import { ArrowDropDown, ArrowRight } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HistoryIcon from "@mui/icons-material/History";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	FormControlLabel,
	FormGroup,
	Tooltip,
	Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { VariableSizeList } from "react-window";

import FilterSearch from "../../../../design/filter-search";
import { deCamelCase } from "../../../../utils/helpers";
import { Priority } from "../../../../utils/types";
import useViewport from "../../../../utils/use-viewport";
import Highlight from "../../../Highlight";
import CrossSkillFilterCheckbox from "./ui/cross-skill-filter-checkbox";
import WorkerSubTypeFilter from "./ui/filter/worker-sub-type";

const FILTER_WIDTH = 282;
const BACKGROUND_COLOR = "#fff";
const EMPTY_SPACE_COLOR = "#fff";
const BORDER_COLOR = "rgba(0,0,0,0.12)";
const FONT_COLOR = "rgba(0,0,0,0.87)";
const MIN_CONTENT_HEIGHT = 160;
const SEARCH_HEIGHT = 49;
const FILTER_HEIGHT = 49;
const ACTION_BAR_HEIGHT = 58;
const SEARCH_BAR_REQUIRED_COUNT = 10;

/* This CSS style is applied when the drawer is opened */
const openedStyle = {
	opacity: 1,
};

/* This CSS style is applied when the drawer is closed */
const closedStyle = {
	opacity: 0,
};

interface CrossSkillingFilterProps {
	filterControllerProps: any;
	filterControllerEvents: any;
	filterStatus: boolean;
	allSkillLevels: any[];
	allManagers: any[];
}

const CrossSkillingFilter: React.FC<CrossSkillingFilterProps> = ({
	filterControllerProps,
	filterControllerEvents,
	filterStatus,
	allSkillLevels,
	allManagers,
}) => {
	const boxRef = useRef<HTMLDivElement>(null);
	const { height, width } = useViewport();
	const [filtersContainerHeight, setFiltersContainerHeight] =
		useState<number>(0);

	const {
		isSkillLevelChecked,
		isSkillChecked,
		isJobChecked,
		isLocationChecked,
		isWorkerTypeChecked,
		isWorkerSubTypeChecked,
		isSkillPriorityChecked,
		isManagerChecked,
		isWorkCenterChecked,
		isShiftPatternChecked,
		isSkillExpanded,
		filterUpdateCount,
		filteredJobs,
		filteredWorkcenters,
		searchedWorkcenter,
		searchedShiftPatterns,
		searchedShiftPattern,
		searchedWorkcenters,
		allWorkerTypes,
		allWorkerSubTypes,
		allSkillPriorities,
		allShiftPatterns,
		jobsExpanded,
		workcenterExpanded,
		shiftPatternExpanded,
		filteredLocations,
		filteredSkills,
		filteredManagers,
		skillsExpanded,
		locationsExpanded,
		managerExpanded,
		workerTypeExpanded,
		workerSubTypeExpanded,
		skillPriorityExpanded,
		searchedJob,
		searchedJobs,
		searchedSkill,
		searchedSkills,
		searchedManager,
		searchedLocation,
		searchedLocations,
		skillListRef,
		rowHeightsForSkill,
		getSkillItemSize,
		getJobItemSize,
		getLocationItemSize,
		getWorkcenterItemSize,
		getShiftPatternItemSize,
	} = filterControllerProps;

	const {
		resetFilters,
		applyFilter,
		expandSkill,
		setSkillPriorityExpanded,
		setLocationsExpanded,
		setJobsExpanded,
		setWorkcenterExpanded,
		setShiftPatternExpanded,
		setSkillsExpanded,
		setManagerExpanded,
		setWorkerTypeExpanded,
		setWorkerSubTypeExpanded,
		setSearchedJob,
		setSearchedManager,
		setSearchedShiftPattern,
		setSearchedWorkcenter,
		setSearchedLocation,
		setSearchedSkill,
		setFilterUpdateCount,
		setRowHeightForSkill,
	} = filterControllerEvents;

	const isSkillInsightsEnabled = useSelector(
		(state: any) => state.user.userData.skillInsightsEnabled
	);

	const isSkillPriorityEnabled = useSelector(
		(state: any) => state.user.userData.skillPriorityEnabled
	);

	const isWorkerSubTypeEnabled = useSelector(
		(state: any) => state.user.userData.workerSubTypeEnabled
	);

	const sortedLocations = [...searchedLocations].sort((a, b) =>
		a.name.trim().localeCompare(b.name.trim())
	);

	const sortedJobs = [...searchedJobs].sort((a, b) =>
		a.name.trim().localeCompare(b.name.trim())
	);

	const sortedSkills = [...searchedSkills].sort((a, b) =>
		a.name.trim().localeCompare(b.name.trim())
	);

	const sortedManagers = [...filteredManagers].sort((a, b) =>
		a.name.trim().localeCompare(b.name.trim())
	);

	const sortedWorkcenters = [...searchedWorkcenters].sort((a, b) =>
		a.name.trim().localeCompare(b.name.trim())
	);

	const sortedShiftPatterns = [...searchedShiftPatterns].sort((a, b) =>
		a.name.trim().localeCompare(b.name.trim())
	);

	const sortedWorkerTypes = [...allWorkerTypes].sort((a, b) =>
		a.name.trim().localeCompare(b.name.trim())
	);

	const sortedWorkerSubTypes = [...allWorkerSubTypes].sort((a, b) =>
		a.name.trim().localeCompare(b.name.trim())
	);

	useEffect(() => {
		const getPosition = () => {
			try {
				if (boxRef.current) {
					const extraSpace = isSkillInsightsEnabled ? 248 : 190;
					const newFiltersHeight = height - extraSpace;
					if (newFiltersHeight >= 696) {
						setFiltersContainerHeight(height - extraSpace);
						setFilterUpdateCount((prevState: number) => prevState + 1);
					} else {
						setFiltersContainerHeight(696);
					}
				}
			} catch (e) {
				// Handle error
			}
		};
		getPosition();
	}, [height, setFilterUpdateCount, isSkillInsightsEnabled]);

	const gotManagers = allManagers.length > 0;
	const gotSkillPriority =
		allSkillPriorities.length > 0 && isSkillPriorityEnabled;
	const gotShiftPatterns = allShiftPatterns?.length > 0;
	let removeSpace = 444;

	removeSpace -= gotManagers ? 0 : 49;
	removeSpace -= gotSkillPriority ? 0 : 49;
	removeSpace -= gotShiftPatterns ? 0 : 49;

	const SkillRows: React.FC<any> = ({ index, style, data }) => {
		const {
			sortedSkills,
			rowHeightsForSkill,
			setRowHeightForSkill,
			allSkillLevels,
			applyFilter,
			expandSkill,
			isSkillChecked,
			isSkillExpanded,
			isSkillLevelChecked,
			searchedSkill,
		} = data;
		const skill = sortedSkills[index];

		const isFirst = index === 0;
		const isLast = index === sortedSkills.length - 1;

		const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
			if (e.target.checked) {
				setRowHeightForSkill(index, skill.id, 30 + allSkillLevels.length * 30);
			} else {
				setRowHeightForSkill(index, skill.id, 30);
			}
			applyFilter("skill", e.target.checked ?? false, skill.id, "", skill.name);
		};

		const skillLevels = useMemo(() => {
			return allSkillLevels.map((level: any) => (
				<FormControlLabel
					key={`level-${skill.id}-${level.id}`}
					label={<span style={{ fontSize: "14px" }}>{level.name}</span>}
					control={
						<CrossSkillFilterCheckbox
							checked={isSkillLevelChecked(skill.id, level.id)}
							level={level.level}
							repCode={level.repCode}
							value={level.value}
							backColorCode={level.backColorCode}
							foreColorCode={level.foreColorCode}
							onChange={(e) => {
								applyFilter(
									"skill",
									e.target.checked ?? false,
									skill.id,
									level.id,
									skill.name
								);
							}}
						/>
					}
				/>
			));
		}, [allSkillLevels, applyFilter, isSkillLevelChecked, skill]);

		return (
			<div
				style={{
					...style,
					height: rowHeightsForSkill[index],
					paddingTop: isFirst ? "12px" : "0px",
					paddingBottom: isLast ? "12px" : "0px",
				}}
			>
				<Box key={`skill-level-${skill.id}`}>
					<FormControlLabel
						control={
							<CrossSkillFilterCheckbox
								onClick={() => expandSkill(skill.id)}
								checked={isSkillChecked(skill.id)}
								level={-1}
								onChange={handleCheckboxChange}
							/>
						}
						label={
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
								}}
							>
								{isSkillExpanded(skill.id) ? <ArrowDropDown /> : <ArrowRight />}
								<Tooltip title={skill.name}>
									<span
										style={{
											fontSize: "14px",
											textOverflow: "ellipsis",
											overflow: "hidden",
											whiteSpace: "nowrap",
										}}
									>
										<Highlight value={skill.name} searchText={searchedSkill} />
									</span>
								</Tooltip>
							</div>
						}
					/>
					<Box
						sx={{
							display: isSkillExpanded(skill.id) ? "flex" : "none",
							flexDirection: "column",
							ml: 4,
						}}
					>
						{skillLevels}
					</Box>
				</Box>
			</div>
		);
	};

	const LocationRows: React.FC<any> = ({ index, style, data }) => {
		const location = data[index];

		const isFirst = index === 0;
		const isLast = index === data.length - 1;

		return (
			<div
				style={{
					...style,
					paddingTop: isFirst ? "12px" : "0px",
					paddingBottom: isLast ? "12px" : "0px",
				}}
			>
				<FormControlLabel
					key={`filter-location-${location.id}`}
					control={
						<CrossSkillFilterCheckbox
							checked={isLocationChecked(location.id)}
							onChange={(e) => {
								if (e.target.checked) {
									applyFilter("location", true, location.id, "", location.name);
								} else {
									applyFilter(
										"location",
										false,
										location.id,
										"",
										location.name
									);
								}
							}}
						/>
					}
					label={
						<Box display='flex' alignItems='center'>
							<Tooltip title={location.name}>
								<span
									style={{
										fontSize: "14px",
										marginLeft: "10px",
										textOverflow: "ellipsis",
										overflow: "hidden",
										whiteSpace: "nowrap",
									}}
								>
									<Highlight
										value={location.name}
										searchText={searchedLocation}
									/>
								</span>
							</Tooltip>
						</Box>
					}
				/>
			</div>
		);
	};

	const WorkcenterRows: React.FC<any> = ({ index, style, data }) => {
		const workcenter = data[index];

		const isFirst = index === 0;
		const isLast = index === data.length - 1;

		return (
			<div
				style={{
					...style,
					paddingTop: isFirst ? "12px" : "0px",
					paddingBottom: isLast ? "12px" : "0px",
				}}
			>
				<FormControlLabel
					key={`filter-workcenter-${workcenter.id}`}
					control={
						<CrossSkillFilterCheckbox
							checked={isWorkCenterChecked(workcenter.id)}
							onChange={(e) => {
								if (e.target.checked) {
									applyFilter(
										"workCenter",
										true,
										workcenter.id,
										"",
										workcenter.name
									);
								} else {
									applyFilter(
										"workCenter",
										false,
										workcenter.id,
										"",
										workcenter.name
									);
								}
							}}
						/>
					}
					label={
						<Box display='flex' alignItems='center'>
							<Tooltip title={workcenter.name}>
								<span
									style={{
										fontSize: "14px",
										marginLeft: "10px",
										textOverflow: "ellipsis",
										overflow: "hidden",
										whiteSpace: "nowrap",
									}}
								>
									<Highlight
										value={workcenter.name}
										searchText={searchedWorkcenter}
									/>
								</span>
							</Tooltip>
						</Box>
					}
				/>
			</div>
		);
	};

	const ShiftPatternRows: React.FC<any> = ({ index, style, data }) => {
		const shiftPattern = data[index];

		const isFirst = index === 0;
		const isLast = index === data.length - 1;

		return (
			<div
				style={{
					...style,
					paddingTop: isFirst ? "12px" : "0px",
					paddingBottom: isLast ? "12px" : "0px",
				}}
			>
				<FormControlLabel
					key={`filter-shiftPattern-${shiftPattern.id}`}
					control={
						<CrossSkillFilterCheckbox
							checked={isShiftPatternChecked(shiftPattern.id)}
							onChange={(e) => {
								if (e.target.checked) {
									applyFilter(
										"shiftPattern",
										true,
										shiftPattern.id,
										"",
										shiftPattern.name
									);
								} else {
									applyFilter(
										"shiftPattern",
										false,
										shiftPattern.id,
										"",
										shiftPattern.name
									);
								}
							}}
						/>
					}
					label={
						<Box display='flex' alignItems='center'>
							<Tooltip title={shiftPattern.name}>
								<span
									style={{
										fontSize: "14px",
										marginLeft: "10px",
										textOverflow: "ellipsis",
										overflow: "hidden",
										whiteSpace: "nowrap",
									}}
								>
									<Highlight
										value={shiftPattern.name}
										searchText={searchedShiftPattern}
									/>
								</span>
							</Tooltip>
						</Box>
					}
				/>
			</div>
		);
	};

	const JobRows: React.FC<any> = ({ index, style, data }) => {
		const job = data[index];

		const isFirst = index === 0;
		const isLast = index === data.length - 1;

		return (
			<div
				style={{
					...style,
					paddingTop: isFirst ? "12px" : "0px",
					paddingBottom: isLast ? "12px" : "0px",
				}}
			>
				<FormControlLabel
					key={`filter-job-${job.id}`}
					control={
						<CrossSkillFilterCheckbox
							checked={isJobChecked(job.id)}
							onChange={(e) => {
								if (e.target.checked) {
									applyFilter("job", true, job.id, "", job.name);
								} else {
									applyFilter("job", false, job.id);
								}
							}}
						/>
					}
					label={
						<Box display='flex' alignItems='center'>
							<Tooltip title={job.name}>
								<span
									style={{
										fontSize: "14px",
										marginLeft: "10px",
										textOverflow: "ellipsis",
										overflow: "hidden",
										whiteSpace: "nowrap",
									}}
								>
									<Highlight value={job.name} searchText={searchedJob} />
								</span>
							</Tooltip>
						</Box>
					}
				/>
			</div>
		);
	};

	return (
		<Box
			ref={boxRef}
			sx={{
				width: `${FILTER_WIDTH}px`,
				height: filtersContainerHeight,
				borderRadius: "0 0 0 15px",
				border: `1px solid ${BORDER_COLOR}`,
				borderTop: "none",
				borderBottom: "none",
				borderLeft: "none",
				backgroundColor: EMPTY_SPACE_COLOR,
				position: "relative",
				overflow: "hidden",
			}}
		>
			<Button
				startIcon={<HistoryIcon sx={{ fontSize: "18px", marginLeft: "4px" }} />}
				onClick={resetFilters}
				fullWidth={true}
				sx={{
					display: "flex",
					alignItems: "center",
					width: "100%",
					textAlign: "left",
					padding: "12px 24px",
					borderBottom: `1px solid ${BORDER_COLOR}`,
					backgroundColor: BACKGROUND_COLOR,
					fontSize: "13px",
					lineHeight: "22px",
					justifyContent: "flex-start",
					fontFamily: "Roboto",
					height: `${ACTION_BAR_HEIGHT}px`,
					"&:hover": {
						backgroundColor: "#F9F9F9",
					},
					borderRadius: 0,
				}}
				variant='text'
			>
				Clear
			</Button>
			<Accordion
				key={`location-filter-${filterUpdateCount}`}
				expanded={locationsExpanded}
				disableGutters
				sx={{
					boxShadow: "none",
					border: 1,
					borderLeft: "none",
					borderRight: "none",
					borderBottom: "none",
					borderTop: "none",
					"&.MuiAccordion-root": {
						borderTopLeftRadius: 0,
						borderTopRightRadius: 0,
						borderBottomLeftRadius: 0,
						borderBottomRightRadius: 0,
					},
				}}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls={"panel0bh-content"}
					id={"panel0bh-header"}
					sx={{
						display: "flex",
						justifyContent: "space-between",
						padding: "0 24px",
						"&:hover": {
							backgroundColor: "#F9F9F9",
						},
					}}
					onClick={() => {
						if (locationsExpanded) {
							setLocationsExpanded(false);
						} else {
							setSkillPriorityExpanded(false);
							setLocationsExpanded(true);
							setJobsExpanded(false);
							setWorkcenterExpanded(false);
							setManagerExpanded(false);
							setSkillsExpanded(false);
							setWorkerTypeExpanded(false);
							setWorkerSubTypeExpanded(false);
							setShiftPatternExpanded(false);
						}
					}}
				>
					<Typography
						sx={{
							fontSize: "14px",
							fontFamily: "Roboto",
							lineHeight: "20px",
							fontWeight: 400,
							color: "rgba(0,0,0,0.87)",
						}}
					>
						Locations ({filteredLocations.length})
					</Typography>
				</AccordionSummary>
				{locationsExpanded && (
					<Box
						sx={{
							width: `${FILTER_WIDTH}px`,
							padding: "0px 24px",
							height: `${SEARCH_HEIGHT}px`,
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "flex-start",
								justifyContent: "center",
								height: "48px",
								flex: 1,
							}}
						>
							<FilterSearch
								searchedValue={searchedLocation}
								setSearchedValue={setSearchedLocation}
							/>
						</Box>
					</Box>
				)}
				<AccordionDetails
					sx={{
						margin: "0px -24px",
						padding: "0px",
						borderBottomLeftRadius: 0,
						height: `${filtersContainerHeight - removeSpace - SEARCH_HEIGHT}px`,
					}}
				>
					<Box
						sx={{
							width: `${FILTER_WIDTH}px`,
							overflowY: "auto",
							margin: "0px 24px",
							padding: "0px 8px",
							height: `${filtersContainerHeight - removeSpace}px`,
							boxShadow:
								"inset 0px 11px 8px -10px #CCC, inset 0px -11px 8px -10px #CCC",
						}}
					>
						<FormGroup>
							<VariableSizeList
								height={filtersContainerHeight - removeSpace}
								width={"100%"}
								itemSize={(index) => getLocationItemSize(index)}
								itemCount={sortedLocations.length}
								overscanCount={5}
								itemData={sortedLocations}
							>
								{LocationRows}
							</VariableSizeList>
						</FormGroup>
					</Box>
				</AccordionDetails>
			</Accordion>
			<Accordion
				key={`worker-center-filter-${filterUpdateCount}`}
				expanded={workcenterExpanded}
				disableGutters
				sx={{
					boxShadow: "none",
					border: 1,
					borderLeft: "none",
					borderRight: "none",
					borderBottom: "none",
					borderTop: workcenterExpanded ? `1px solid ${BORDER_COLOR}` : "none",
					"&.MuiAccordion-root": {
						borderTopLeftRadius: 0,
						borderTopRightRadius: 0,
						borderBottomLeftRadius: 0,
						borderBottomRightRadius: 0,
					},
				}}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls={"panel1bh-content"}
					id={"panel1bh-header"}
					sx={{
						display: "flex",
						justifyContent: "space-between",
						padding: "0 24px",
						"&:hover": {
							backgroundColor: "#F9F9F9",
						},
					}}
					onClick={() => {
						if (workcenterExpanded) {
							setWorkcenterExpanded(false);
						} else {
							setWorkcenterExpanded(true);
							setSkillPriorityExpanded(false);
							setLocationsExpanded(false);
							setWorkerTypeExpanded(false);
							setWorkerSubTypeExpanded(false);
							setManagerExpanded(false);
							setSkillsExpanded(false);
							setJobsExpanded(false);
							setShiftPatternExpanded(false);
						}
					}}
				>
					<Typography
						sx={{
							fontSize: "14px",
							fontFamily: "Roboto",
							lineHeight: "20px",
							fontWeight: 400,
							color: "rgba(0,0,0,0.87)",
						}}
					>
						Work Center ({sortedWorkcenters.length})
					</Typography>
				</AccordionSummary>
				{workcenterExpanded && (
					<Box
						sx={{
							width: `${FILTER_WIDTH}px`,
							padding: "0px 24px",
							height: `${SEARCH_HEIGHT}px`,
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "flex-start",
								justifyContent: "center",
								height: "48px",
								flex: 1,
							}}
						>
							<FilterSearch
								searchedValue={searchedWorkcenter}
								setSearchedValue={setSearchedWorkcenter}
							/>
						</Box>
					</Box>
				)}
				<AccordionDetails
					sx={{
						margin: "0px -24px",
						padding: "0px",
						borderBottomLeftRadius: 0,
						height: `${filtersContainerHeight - removeSpace - SEARCH_HEIGHT}px`,
					}}
				>
					<Box
						sx={{
							width: `${FILTER_WIDTH}px`,
							overflowY: "auto",
							margin: "0px 24px",
							padding: "0px 8px",
							height: `${filtersContainerHeight - removeSpace}px`,
							boxShadow:
								"inset 0px 11px 8px -10px #CCC, inset 0px -11px 8px -10px #CCC",
						}}
					>
						<FormGroup>
							<VariableSizeList
								height={filtersContainerHeight - removeSpace}
								width={"100%"}
								itemSize={(index) => getWorkcenterItemSize(index)}
								itemCount={sortedWorkcenters.length}
								overscanCount={5}
								itemData={sortedWorkcenters}
							>
								{WorkcenterRows}
							</VariableSizeList>
						</FormGroup>
					</Box>
				</AccordionDetails>
			</Accordion>
			{gotShiftPatterns && (
				<Accordion
					key={`shift-pattern-filter-${filterUpdateCount}`}
					expanded={shiftPatternExpanded}
					disableGutters
					sx={{
						boxShadow: "none",
						border: 1,
						borderLeft: "none",
						borderRight: "none",
						borderBottom: "none",
						borderTop: shiftPatternExpanded
							? `1px solid ${BORDER_COLOR}`
							: "none",
						"&.MuiAccordion-root": {
							borderTopLeftRadius: 0,
							borderTopRightRadius: 0,
							borderBottomLeftRadius: 0,
							borderBottomRightRadius: 0,
						},
					}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls={"shift-pattern-content"}
						id={"shift-pattern-header"}
						sx={{
							display: "flex",
							justifyContent: "space-between",
							padding: "0 24px",
							"&:hover": {
								backgroundColor: "#F9F9F9",
							},
						}}
						onClick={() => {
							if (shiftPatternExpanded) {
								setShiftPatternExpanded(false);
							} else {
								setShiftPatternExpanded(true);
								setWorkcenterExpanded(false);
								setSkillPriorityExpanded(false);
								setLocationsExpanded(false);
								setWorkerTypeExpanded(false);
								setWorkerSubTypeExpanded(false);
								setManagerExpanded(false);
								setSkillsExpanded(false);
								setJobsExpanded(false);
							}
						}}
					>
						<Typography
							sx={{
								fontSize: "14px",
								fontFamily: "Roboto",
								lineHeight: "20px",
								fontWeight: 400,
								color: "rgba(0,0,0,0.87)",
							}}
						>
							Shift Pattern ({sortedShiftPatterns.length})
						</Typography>
					</AccordionSummary>
					{shiftPatternExpanded && (
						<Box
							sx={{
								width: `${FILTER_WIDTH}px`,
								padding: "0px 24px",
								height: `${SEARCH_HEIGHT}px`,
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "flex-start",
									justifyContent: "center",
									height: "48px",
									flex: 1,
								}}
							>
								<FilterSearch
									searchedValue={searchedShiftPattern}
									setSearchedValue={setSearchedShiftPattern}
								/>
							</Box>
						</Box>
					)}
					<AccordionDetails
						sx={{
							margin: "0px -24px",
							padding: "0px",
							borderBottomLeftRadius: 0,
							height: `${
								filtersContainerHeight - removeSpace - SEARCH_HEIGHT
							}px`,
						}}
					>
						<Box
							sx={{
								width: `${FILTER_WIDTH}px`,
								overflowY: "auto",
								margin: "0px 24px",
								padding: "0px 8px",
								height: `${filtersContainerHeight - removeSpace}px`,
								boxShadow:
									"inset 0px 11px 8px -10px #CCC, inset 0px -11px 8px -10px #CCC",
							}}
						>
							<FormGroup>
								<VariableSizeList
									height={filtersContainerHeight - removeSpace}
									width={"100%"}
									itemSize={(index) => getShiftPatternItemSize(index)}
									itemCount={sortedShiftPatterns.length}
									overscanCount={5}
									itemData={sortedShiftPatterns}
								>
									{ShiftPatternRows}
								</VariableSizeList>
							</FormGroup>
						</Box>
					</AccordionDetails>
				</Accordion>
			)}
			<Accordion
				key={`worker-type-filter-${filterUpdateCount}`}
				expanded={workerTypeExpanded}
				disableGutters
				sx={{
					boxShadow: "none",
					border: 1,
					borderLeft: "none",
					borderRight: "none",
					borderBottom: "none",
					borderTop: workerTypeExpanded ? `1px solid ${BORDER_COLOR}` : "none",
					"&.MuiAccordion-root": {
						borderTopLeftRadius: 0,
						borderTopRightRadius: 0,
						borderBottomLeftRadius: 0,
						borderBottomRightRadius: 0,
					},
				}}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls={"panel2bh-content"}
					id={"panel2bh-header"}
					sx={{
						display: "flex",
						justifyContent: "space-between",
						padding: "0 24px",
						"&:hover": {
							backgroundColor: "#F9F9F9",
						},
					}}
					onClick={() => {
						if (workerTypeExpanded) {
							setWorkerTypeExpanded(false);
						} else {
							setSkillPriorityExpanded(false);
							setLocationsExpanded(false);
							setWorkerTypeExpanded(true);
							setWorkcenterExpanded(false);
							setWorkerSubTypeExpanded(false);
							setManagerExpanded(false);
							setSkillsExpanded(false);
							setJobsExpanded(false);
							setShiftPatternExpanded(false);
						}
					}}
				>
					<Typography
						sx={{
							fontSize: "14px",
							fontFamily: "Roboto",
							lineHeight: "20px",
							fontWeight: 400,
							color: "rgba(0,0,0,0.87)",
						}}
					>
						Worker Type ({sortedWorkerTypes.length})
					</Typography>
				</AccordionSummary>
				<AccordionDetails
					sx={{
						margin: "0px -24px",
						padding: "0px",
						borderBottomLeftRadius: 0,
						height: `${filtersContainerHeight - removeSpace}px`,
					}}
				>
					<Box
						sx={{
							width: `${FILTER_WIDTH}px`,
							height: `${filtersContainerHeight - removeSpace}px`,
							overflowY: "auto",
							margin: "0px 24px",
							padding: "12px 8px",
							boxShadow:
								"inset 0px 11px 8px -10px #CCC, inset 0px -11px 8px -10px #CCC",
						}}
					>
						<FormGroup>
							<>
								{sortedWorkerTypes.map(
									(workerType: { id: number; text: string; name: string }) =>
										isWorkerSubTypeEnabled && workerType.id === 3 ? (
											<WorkerSubTypeFilter
												key={`worker-type-${workerType.id}`}
												workerType={workerType}
												workerSubTypes={sortedWorkerSubTypes}
												isWorkerTypeChecked={isWorkerTypeChecked}
												isWorkerSubTypeChecked={isWorkerSubTypeChecked}
												applyFilter={applyFilter}
												filterUpdateCount={filterUpdateCount}
											/>
										) : (
											<FormControlLabel
												key={`worker-type-${workerType.id}`}
												control={
													<CrossSkillFilterCheckbox
														checked={isWorkerTypeChecked(workerType.id)}
														onChange={(e) => {
															if (e.target.checked) {
																applyFilter(
																	"workerType",
																	true,
																	workerType.id,
																	"",
																	workerType.text
																);
															} else {
																applyFilter("workerType", false, workerType.id);
															}
														}}
													/>
												}
												label={
													<Box display='flex' alignItems='center'>
														<span
															style={{ fontSize: "14px", marginLeft: "10px" }}
														>
															{workerType.text ?? deCamelCase(workerType.name)}
														</span>
													</Box>
												}
											/>
										)
								)}
							</>
						</FormGroup>
					</Box>
				</AccordionDetails>
			</Accordion>

			<Accordion
				key={`job-filter-${filterUpdateCount}`}
				expanded={jobsExpanded}
				disableGutters
				sx={{
					boxShadow: "none",
					border: 1,
					borderLeft: "none",
					borderRight: "none",
					borderBottom: "none",
					borderTop: jobsExpanded ? `1px solid ${BORDER_COLOR}` : "none",
					"&.MuiAccordion-root": {
						borderTopLeftRadius: 0,
						borderTopRightRadius: 0,
						borderBottomLeftRadius: 0,
						borderBottomRightRadius: 0,
					},
				}}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls={"panel3bh-content"}
					id={"panel3bh-header"}
					sx={{
						display: "flex",
						justifyContent: "space-between",
						padding: "0 24px",
						"&:hover": {
							backgroundColor: "#F9F9F9",
						},
					}}
					onClick={() => {
						if (jobsExpanded) {
							setJobsExpanded(false);
						} else {
							setSkillPriorityExpanded(false);
							setLocationsExpanded(false);
							setJobsExpanded(true);
							setWorkcenterExpanded(false);
							setManagerExpanded(false);
							setSkillsExpanded(false);
							setWorkerTypeExpanded(false);
							setWorkerSubTypeExpanded(false);
							setShiftPatternExpanded(false);
						}
					}}
				>
					<Typography
						sx={{
							fontSize: "14px",
							fontFamily: "Roboto",
							lineHeight: "20px",
							fontWeight: 400,
							color: "rgba(0,0,0,0.87)",
						}}
					>
						Job Roles ({filteredJobs.length})
					</Typography>
				</AccordionSummary>
				{jobsExpanded && (
					<Box
						sx={{
							width: `${FILTER_WIDTH}px`,
							padding: "0px 24px",
							height: `${SEARCH_HEIGHT}px`,
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "flex-start",
								justifyContent: "center",
								height: "48px",
								flex: 1,
							}}
						>
							<FilterSearch
								searchedValue={searchedJob}
								setSearchedValue={setSearchedJob}
							/>
						</Box>
					</Box>
				)}
				<AccordionDetails
					sx={{
						margin: "0px -24px",
						padding: "0px",
						borderBottomLeftRadius: 0,
						height: `${filtersContainerHeight - removeSpace - SEARCH_HEIGHT}px`,
					}}
				>
					<Box
						sx={{
							width: `${FILTER_WIDTH}px`,
							overflowY: "auto",
							margin: "0px 24px",
							padding: "0px 8px",
							height: `${
								filtersContainerHeight - removeSpace - SEARCH_HEIGHT
							}px`,
							boxShadow:
								"inset 0px 11px 8px -10px #CCC, inset 0px -11px 8px -10px #CCC",
						}}
					>
						<FormGroup>
							<VariableSizeList
								height={filtersContainerHeight - removeSpace - SEARCH_HEIGHT}
								width={"100%"}
								itemSize={(index) => getJobItemSize(index)}
								itemCount={sortedJobs.length}
								overscanCount={5}
								itemData={sortedJobs}
							>
								{JobRows}
							</VariableSizeList>
						</FormGroup>
					</Box>
				</AccordionDetails>
			</Accordion>
			{isSkillPriorityEnabled && (
				<Accordion
					key={`skill-priority-filter-${filterUpdateCount}`}
					expanded={skillPriorityExpanded}
					disableGutters
					sx={{
						boxShadow: "none",
						border: 1,
						borderLeft: "none",
						borderRight: "none",
						borderBottom: "none",
						borderTop: skillPriorityExpanded
							? `1px solid ${BORDER_COLOR}`
							: "none",
						"&.MuiAccordion-root": {
							borderTopLeftRadius: 0,
							borderTopRightRadius: 0,
							borderBottomLeftRadius: 0,
							borderBottomRightRadius: 0,
						},
					}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls={"panel4bh-content"}
						id={"panel4bh-header"}
						sx={{
							display: "flex",
							justifyContent: "space-between",
							padding: "0 24px",
							"&:hover": {
								backgroundColor: "#F9F9F9",
							},
						}}
						onClick={() => {
							if (skillPriorityExpanded) {
								setSkillPriorityExpanded(false);
							} else {
								setSkillPriorityExpanded(true);
								setLocationsExpanded(false);
								setJobsExpanded(false);
								setWorkcenterExpanded(false);
								setManagerExpanded(false);
								setSkillsExpanded(false);
								setWorkerTypeExpanded(false);
								setWorkerSubTypeExpanded(false);
								setShiftPatternExpanded(false);
							}
						}}
					>
						<Typography
							sx={{
								fontSize: "14px",
								fontFamily: "Roboto",
								lineHeight: "20px",
								fontWeight: 400,
								color: "rgba(0,0,0,0.87)",
							}}
						>
							Skill Priorities ({allSkillPriorities.length})
						</Typography>
					</AccordionSummary>
					<AccordionDetails
						sx={{
							margin: "0px -24px",
							padding: "0px",
							borderBottomLeftRadius: 0,
							height: `${filtersContainerHeight - removeSpace}px`,
						}}
					>
						<Box
							sx={{
								width: `${FILTER_WIDTH}px`,
								overflowY: "auto",
								margin: "0px 24px",
								padding: "12px 8px",
								height: `${filtersContainerHeight - removeSpace}px`,
								boxShadow:
									"inset 0px 11px 8px -10px #CCC, inset 0px -11px 8px -10px #CCC",
							}}
						>
							<FormGroup>
								<>
									{allSkillPriorities.map((skillPriority: Priority) => (
										<FormControlLabel
											key={`worker-type-${skillPriority.id}`}
											control={
												<CrossSkillFilterCheckbox
													checked={isSkillPriorityChecked(skillPriority.id)}
													onChange={(e) => {
														if (e.target.checked) {
															applyFilter(
																"skillPriority",
																true,
																skillPriority.id,
																"",
																`${skillPriority.code} (${skillPriority.name})`
															);
														} else {
															applyFilter(
																"skillPriority",
																false,
																skillPriority.id
															);
														}
													}}
												/>
											}
											label={
												<Box display='flex' alignItems='center'>
													<span
														style={{ fontSize: "14px", marginLeft: "10px" }}
													>
														{`${skillPriority.code} (${skillPriority.name})`}
													</span>
												</Box>
											}
										/>
									))}
								</>
							</FormGroup>
						</Box>
					</AccordionDetails>
				</Accordion>
			)}
			<Accordion
				key={`skill-filter-${filterUpdateCount}`}
				expanded={skillsExpanded}
				disableGutters
				sx={{
					boxShadow: "none",
					border: 1,
					borderLeft: "none",
					borderRight: "none",
					borderBottom: "none",
					borderTop: skillsExpanded ? `1px solid ${BORDER_COLOR}` : "none",
					"&.MuiAccordion-root": {
						borderTopLeftRadius: 0,
						borderTopRightRadius: 0,
						borderBottomLeftRadius: 0,
						borderBottomRightRadius: 0,
					},
				}}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls={"panel5bh-content"}
					id={"panel5bh-header"}
					sx={{
						display: "flex",
						justifyContent: "space-between",
						padding: "0 24px",
						"&:hover": {
							backgroundColor: "#F9F9F9",
						},
					}}
					onClick={() => {
						if (skillsExpanded) {
							setSkillsExpanded(false);
						} else {
							setSkillPriorityExpanded(false);
							setLocationsExpanded(false);
							setSkillsExpanded(true);
							setWorkcenterExpanded(false);
							setManagerExpanded(false);
							setJobsExpanded(false);
							setWorkerTypeExpanded(false);
							setWorkerSubTypeExpanded(false);
							setShiftPatternExpanded(false);
						}
						// resetFilters();
					}}
				>
					<Typography
						sx={{
							fontSize: "14px",
							fontFamily: "Roboto",
							lineHeight: "20px",
							fontWeight: 400,
							color: "rgba(0,0,0,0.87)",
						}}
					>
						Skills ({filteredSkills.length})
					</Typography>
				</AccordionSummary>
				{skillsExpanded && (
					<Box
						sx={{
							width: `${FILTER_WIDTH}px`,
							padding: "0px 24px",
							height: `${SEARCH_HEIGHT}px`,
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "flex-start",
								justifyContent: "center",
								height: "48px",
								flex: 1,
							}}
						>
							<FilterSearch
								searchedValue={searchedSkill}
								setSearchedValue={setSearchedSkill}
							/>
						</Box>
					</Box>
				)}
				<AccordionDetails
					sx={{
						margin: "0px -24px",
						padding: "0px",
						borderRadius: gotManagers
							? "0px"
							: width >= 1312
							? "0px 0px 0px 15px"
							: "0px",
						height: `${filtersContainerHeight - removeSpace - SEARCH_HEIGHT}px`,
					}}
				>
					<Box
						sx={{
							width: `${FILTER_WIDTH}px`,
							overflowY: "auto",
							margin: "0px 24px",
							padding: "0px 8px",
							height: `${
								filtersContainerHeight - removeSpace - SEARCH_HEIGHT
							}px`,
							boxShadow:
								"inset 0px 11px 8px -10px #CCC, inset 0px -11px 8px -10px #CCC",
						}}
					>
						<FormGroup>
							<VariableSizeList
								ref={skillListRef}
								height={filtersContainerHeight - removeSpace - SEARCH_HEIGHT}
								width={"100%"}
								itemSize={(index) => {
									try {
										const skill = sortedSkills[index];
										return getSkillItemSize(index, skill.id);
									} catch (e) {
										return 30;
									}
								}}
								itemCount={sortedSkills.length}
								overscanCount={5}
								itemData={{
									sortedSkills,
									rowHeightsForSkill,
									setRowHeightForSkill,
									allSkillLevels,
									applyFilter,
									expandSkill,
									isSkillChecked,
									isSkillExpanded,
									isSkillLevelChecked,
									searchedSkill,
								}}
							>
								{SkillRows}
							</VariableSizeList>
						</FormGroup>
					</Box>
				</AccordionDetails>
			</Accordion>
			{gotManagers && (
				<Accordion
					key={`manager-filter-${filterUpdateCount}`}
					expanded={managerExpanded}
					disableGutters
					sx={{
						boxShadow: "none",
						border: 1,
						borderLeft: "none",
						borderRight: "none",
						borderBottom: "none",
						borderTop: managerExpanded ? `1px solid ${BORDER_COLOR}` : "none",
						"&.MuiAccordion-root": {
							borderTopLeftRadius: 0,
							borderTopRightRadius: 0,
							borderBottomLeftRadius: 0,
							borderBottomRightRadius: 0,
						},
					}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls={"panel6bh-content"}
						id={"panel6bh-header"}
						sx={{
							display: "flex",
							justifyContent: "space-between",
							padding: "0 24px",
							"&:hover": {
								backgroundColor: "#F9F9F9",
							},
						}}
						onClick={() => {
							if (managerExpanded) {
								setManagerExpanded(false);
							} else {
								setSkillPriorityExpanded(false);
								setManagerExpanded(true);
								setLocationsExpanded(false);
								setWorkcenterExpanded(false);
								setSkillsExpanded(false);
								setJobsExpanded(false);
								setWorkerTypeExpanded(false);
								setWorkerSubTypeExpanded(false);
								setShiftPatternExpanded(false);
							}
						}}
					>
						<Typography
							sx={{
								fontSize: "14px",
								fontFamily: "Roboto",
								lineHeight: "20px",
								fontWeight: 400,
								color: "rgba(0,0,0,0.87)",
							}}
						>
							Managers ({sortedManagers.length})
						</Typography>
					</AccordionSummary>
					{managerExpanded && (
						<Box
							sx={{
								width: `${FILTER_WIDTH}px`,
								padding: "0px 24px",
								height: `${SEARCH_HEIGHT}px`,
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "flex-start",
									justifyContent: "center",
									height: "48px",
									flex: 1,
								}}
							>
								<FilterSearch
									searchedValue={searchedManager}
									setSearchedValue={setSearchedManager}
								/>
							</Box>
						</Box>
					)}
					<AccordionDetails
						sx={{
							margin: "0px -24px",
							padding: "0px",
							borderRadius: width >= 1312 ? "0px 0px 0px 15px" : "0px",
							height: `${
								filtersContainerHeight - removeSpace - SEARCH_HEIGHT
							}px`,
						}}
					>
						<Box
							sx={{
								width: `${FILTER_WIDTH}px`,
								overflowY: "auto",
								margin: "0px 24px",
								padding: "0px 8px",
								height: `${
									filtersContainerHeight - removeSpace - SEARCH_HEIGHT
								}px`,
								boxShadow:
									"inset 0px 11px 8px -10px #CCC, inset 0px -11px 8px -10px #CCC",
							}}
						>
							<FormGroup>
								<>
									{sortedManagers.map(
										(manager: { id: number; name: string }) => (
											<FormControlLabel
												key={`filter-manager-${manager.id}`}
												control={
													<CrossSkillFilterCheckbox
														checked={isManagerChecked(manager.id)}
														onChange={(e) => {
															if (e.target.checked) {
																applyFilter(
																	"manager",
																	true,
																	manager.id,
																	"",
																	manager.name
																);
															} else {
																applyFilter("manager", false, manager.id);
															}
														}}
													/>
												}
												label={
													<Box display='flex' alignItems='center'>
														<span
															style={{ fontSize: "14px", marginLeft: "10px" }}
														>
															<Highlight
																searchText={searchedManager}
																value={manager.name}
															/>
														</span>
													</Box>
												}
											/>
										)
									)}
								</>
							</FormGroup>
						</Box>
					</AccordionDetails>
				</Accordion>
			)}
		</Box>
	);
};

export default CrossSkillingFilter;
