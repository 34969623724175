// TODO - Type 'any' needs to be fixed.
/* eslint-disable no-mixed-spaces-and-tabs */
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {Autocomplete, CircularProgress, TextField as Text} from "@mui/material";
//import GigandTakeSwitch from "@gigandtake/switch";
// import { Button, IconButton } from "@mui/material";
// import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// import format from "date-fns/format";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers-pro-6";
import {AdapterDayjs} from "@mui/x-date-pickers-pro-6/AdapterDayjs";
import dayjs from "dayjs";
import {Field, Form, Formik} from "formik";
import {TextField} from "formik-mui";
import React, {ReactNode, useEffect} from "react";
import {useDispatch} from "react-redux";

// import Toggle from "../../../../../design/toggle";
import {editDraftShift} from "../../../../../../export/shift-scheduler";
import {resetMaxWorkersByLocationJob} from "../../../../../components/jobs/store/jobs-slice";
import JobColor from "../../../../../design/ui/job-color";
import useViewport from "../../../../../utils/use-viewport";
import {SchedulerWorkCenter} from "../../../../scheduler/store/types";
import useSchedulerData from "../../../controllers/use-scheduler-data";
import SelectSkills from "../create-new-shift-dialog/select-skills";
import useEditShiftDialog from "./controllers/use-edit-shift-dialog";
import {useEditShiftDialogForm} from "./controllers/use-edit-shift-dialog-form";
import EditActionButtons from "./edit-action-buttons";
import {setToNearestTime, useStyles} from "./utils";

interface FilledFormControlProps {
  children: ReactNode;
  customStyles?: Record<string, string | number>;
}

const scrollBarStyles = {
  height: "540px",
  width: "100%",
  overflowY: "auto",
  overflowX: "hidden",
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#888",
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },
  "&::-webkit-scrollbar-corner": {
    background: "transparent",
  },
  "&::-webkit-resizer": {
    background: "transparent",
  },
  "&::-webkit-scrollbar-button": {
    display: "none",
  },
};

const FilledFormControl: React.FC<FilledFormControlProps> = ({
  children,
  customStyles,
}) => {
  const classes = useStyles();
  return (
    <FormControl
      variant="filled"
      className={classes.formControlRoot}
      sx={{ ...customStyles }}
    >
      {children}
    </FormControl>
  );
};

type EditShiftDialogFormProps = {
  dispatchLocal: (arg0: any) => void;
  dispatchLocalSkill: (arg0: any) => void;
  state: any;
  skillState: any;
  handleClose: (() => void) | undefined;
};

const EditShiftDialogForm: React.FC<EditShiftDialogFormProps> = ({
  dispatchLocal,
  dispatchLocalSkill,
  state,
  skillState,
  handleClose,
}) => {
  const classes = useStyles();
  const { width } = useViewport();
  const dispatch = useDispatch();

  const [shiftType, setShiftType] = React.useState("jobs");

  const [
    {
      initialValues,
      schema,
      initialShiftDateTimeValues,
      filteredJobs,
      //selectedDatesOfTheWeek,
      totalHours,
      totalMins,
      shiftId,
    },
    {
      //isDisabled,
      calculateTotalHoursFromEndTime,
      calculateTotalHoursFromStartTime,
      calculateTotalHoursFromWorkers,
      handleWorkcenterChange,
      getNumberOfStaff,
      setInitialShiftDateTimeValue,
      setSelectedDatesOfTheWeek,
      setTotalHours,
    },
  ] = useEditShiftDialog();

  const [
    ,
    {
      onFormSubmit,
      onEndTimeChange,
      onStartTimeChange,
      getLocationWorkcenters,
    },
  ] = useEditShiftDialogForm({
    state,
    skillState,
    setToNearestTime,
    dispatch,
    dispatchLocal,
    dispatchLocalSkill,
    editDraftShift,
    calculateTotalHoursFromStartTime,
    calculateTotalHoursFromEndTime,
  });

  const {
    maxWorkersByJobLocation,
    fetchFilterAndShiftDataStatus,
    workcenters,
    filterAllWorkcenters,
    isShiftBySkillEnabled,
    fetchAllWorkcentersStatus,
    shiftPurposes,
    isShiftPurposeEnabled,
  } = useSchedulerData();

  const locationWorkcenters =
    getLocationWorkcenters && getLocationWorkcenters(workcenters);

  useEffect(() => {
    const newInitialValues = initialValues;
    newInitialValues.startTime = setToNearestTime(initialValues.startTime);
    newInitialValues.endTime = setToNearestTime(initialValues.endTime);
    if (fetchFilterAndShiftDataStatus === "fulfilled") {
      const locationWorkcenter = filterAllWorkcenters?.find(
        (workcenter: SchedulerWorkCenter) =>
          workcenter.id === parseInt(initialValues.shiftData.workCenterId)
      );
      newInitialValues.locationId = locationWorkcenter?.locationId;
    }
    setInitialShiftDateTimeValue &&
      setInitialShiftDateTimeValue(newInitialValues);
    setShiftType(initialValues.jobId ? "jobs" : "skills");
  }, [
    fetchFilterAndShiftDataStatus,
    filterAllWorkcenters,
    initialValues,
    setInitialShiftDateTimeValue,
  ]);

  return (
    <Box
      width="800"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      mr="auto"
      ml="auto"
    >
      <Formik
        key={`edit-shift-${initialShiftDateTimeValues.locationId}`}
        initialValues={initialShiftDateTimeValues}
        validationSchema={schema}
        onSubmit={(values, actions) => {
          if (onFormSubmit) onFormSubmit(values, actions);
        }}
      >
        {({
          isValid,
          dirty,
          values,
          setFieldValue,
          isSubmitting,
          validateForm,
          errors,
        }) => (
          <Form className={classes.formRoot}>
            {/* <Box sx={scrollBarStyles}> */}
            {!isShiftPurposeEnabled ? null : (
              <Box
                display="flex"
                justifyContent="space-between"
                flexDirection={width < 600 ? "column" : "row"}
              >
                <Box width={"100%"}>
                  <FilledFormControl>
                    <Field
                      component={TextField}
                      type="text"
                      select
                      variant="filled"
                      name="shiftPurposeId"
                      id="shiftPurposeId"
                      label="Shift Purpose*"
                      disabled={values.shiftPublished}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue("shiftPurposeId", e.target.value);
                      }}
                      helperText=" "
                      fullWidth
                      InputLabelProps={{
                        shrink: true, // Ensure the label shrinks properly
                      }}
                      SelectProps={{
                        displayEmpty: true,
                        renderValue: (value: any) => {
                          // Show placeholder if value is 0 or falsy
                          if (!value) {
                            return (
                              <Typography
                                sx={{
                                  fontFamily: "Roboto",
                                  fontSize: "16px",
                                  color: "rgba(0, 0, 0, 0.6)",
                                }}
                              >
                                Select a purpose
                              </Typography>
                            );
                          }
                          // Otherwise, display the selected value's name
                          const selectedPurpose = shiftPurposes?.[value];
                          return selectedPurpose ? selectedPurpose.name : "";
                        },
                      }}
                    >
                      {/* Render other shift purposes */}
                      {shiftPurposes &&
                        Object.values(shiftPurposes).map((purpose: any) => (
                          <MenuItem
                            value={purpose.id}
                            key={`shift-purpose-${purpose.id}`}
                          >
                            {purpose.name}
                          </MenuItem>
                        ))}
                    </Field>
                  </FilledFormControl>
                </Box>
              </Box>
            )}

            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection={width < 600 ? "column" : "row"}
              mb={1}
            >
              <Box width={width < 600 ? "100%" : "48%"} mb={2} mr={1}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="Shift Start Time"
                    disabled={values.shiftPublished}
                    value={
                      values.startTime
                        ? dayjs(values.startTime, "YYYY-MM-DDTHH:mm")
                        : null
                    }
                    format="MM/DD/YYYY, hh:mm A"
                    onChange={(newValue) => {
                      if (onStartTimeChange && getNumberOfStaff) {
                        const formattedDate =
                          dayjs(newValue).format("YYYY-MM-DDTHH:mm");
                        onStartTimeChange(formattedDate, {
                          setFieldValue,
                          values,
                        });
                        getNumberOfStaff(
                          values.jobId,
                          values.locationId,
                          new Date(formattedDate).toISOString(),
                          new Date(values.endTime).toISOString(),
                          values.shiftSkills
                        );
                      }
                    }}
                    slotProps={{
                      textField: {
                        variant: "filled",
                        fullWidth: true,
                        InputLabelProps: {
                          shrink: true,
                        },
                        error: errors.startTime ? true : false,
                        helperText: errors.startTime ? errors.startTime : "",
                      },
                    }}
                  />
                </LocalizationProvider>
              </Box>
              <Box width={width < 600 ? "100%" : "48%"}>
                {/* <FilledFormControl>
									<Field
										variant='filled'
										component={TextField}
										name='endTime'
										id='endTime'
										type='datetime-local'
										label='Shift End Time'
										disabled={values.shiftPublished}
										InputLabelProps={{
											shrink: true,
										}}
										inputProps={{
											min: new Date().toLocaleDateString(),
										}}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											if (onEndTimeChange) {
												onEndTimeChange(e, { setFieldValue, values });
											}
										}}
									/>
								</FilledFormControl> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="Shift End Time"
                    disabled={values.shiftPublished}
                    value={
                      values.endTime
                        ? dayjs(values.endTime, "YYYY-MM-DDTHH:mm")
                        : null
                    }
                    format="MM/DD/YYYY, hh:mm A"
                    onChange={(newValue) => {
                      if (onEndTimeChange && getNumberOfStaff) {
                        const formattedDate =
                          dayjs(newValue).format("YYYY-MM-DDTHH:mm");
                        onEndTimeChange(formattedDate, {
                          setFieldValue,
                          values,
                        });
                        getNumberOfStaff(
                          values.jobId,
                          values.locationId,
                          new Date(values.startTime).toISOString(),
                          new Date(formattedDate).toISOString(),
                          values.shiftSkills
                        );
                      }
                    }}
                    slotProps={{
                      textField: {
                        variant: "filled",
                        fullWidth: true,
                        InputLabelProps: {
                          shrink: true,
                        },
                        error: errors.endTime ? true : false,
                        helperText: errors.endTime ? errors.endTime : "",
                      },
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Box>

            <Box width={"100%"}>
              <FilledFormControl>
                <Field
                  component={TextField}
                  type="text"
                  select
                  variant="filled"
                  name="workCenterId"
                  id="workCenterId"
                  label="Location - Workcenter"
                  disabled={values.shiftPublished}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (handleWorkcenterChange) {
                      setFieldValue("workCenterId", e.target.value);
                      handleWorkcenterChange(e.target.value);
                      setFieldValue("jobId", "");
                      let locationWorkcenter = locationWorkcenters?.find(
                        (lwc: any) =>
                          lwc.id.toString() === values?.workCenterId?.toString()
                      );
                      if (fetchFilterAndShiftDataStatus === "fulfilled") {
                        locationWorkcenter = filterAllWorkcenters?.find(
                          (workcenter: SchedulerWorkCenter) =>
                            workcenter.id === parseInt(e.target.value)
                        );
                      }
                      setFieldValue(
                        "locationId",
                        locationWorkcenter?.locationId
                      );
                    }
                  }}
                  helperText=" "
                >
                  {fetchFilterAndShiftDataStatus === "fulfilled" &&
                    filterAllWorkcenters &&
                    filterAllWorkcenters.map(
                      (workcenter: SchedulerWorkCenter) => (
                        <MenuItem
                          value={`${workcenter.id}`}
                          key={`workcenter-${workcenter.id}`}
                          id={`${workcenter.id}`}
                        >
                          {workcenter.nameWithLocation}
                        </MenuItem>
                      )
                    )}
                  {fetchFilterAndShiftDataStatus !== "fulfilled" &&
                    locationWorkcenters &&
                    locationWorkcenters.map((lwc: any) => (
                      <MenuItem value={lwc.id} key={lwc.id} id={lwc.id}>
                        {lwc.locationName} - {lwc.name}
                      </MenuItem>
                    ))}
                </Field>
              </FilledFormControl>
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection={width < 600 ? "column" : "row"}
              mb={3}
            >
              <Box width={width < 600 ? "100%" : "48%"} mr={1}>
                <FilledFormControl>
                  <Field
                    component={TextField}
                    type="number"
                    variant="filled"
                    name="numberOfWorkersNeeded"
                    id="numberOfWorkersNeeded"
                    label="Workers required"
                    disabled={isSubmitting}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (calculateTotalHoursFromWorkers) {
                        setFieldValue("numberOfWorkersNeeded", e.target.value);
                        calculateTotalHoursFromWorkers(values, e.target.value);
                      }
                    }}
                  />
                </FilledFormControl>
              </Box>
              <Box width={width < 600 ? "100%" : "48%"}>
                <FilledFormControl>
                  <Field
                    component={TextField}
                    disabled
                    value={
                      totalHours !== undefined && totalMins !== undefined
                        ? `${totalHours <= 0 ? "0 hrs" : `${totalHours} hrs`} ${
                            totalMins <= 0 ? "0 mins" : `${totalMins} mins`
                          }`
                        : ""
                    }
                    type="text"
                    variant="filled"
                    label="Total Time"
                    name="total-hours"
                  />
                </FilledFormControl>
              </Box>
            </Box>

            {!isShiftBySkillEnabled ? null : (
              <Box mt={3} mb={1}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    fontFamily: "Roboto",
                  }}
                >
                  Shift type
                </Typography>
                <FormControl
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={shiftType}
                    onChange={(e) => {
                      if (e.target.value === "skills") {
                        setFieldValue("jobId", "");
                        setFieldValue("shiftSkills", initialValues.shiftSkills);
                      } else {
                        setFieldValue(
                          "jobId",
                          initialValues.jobId ? initialValues.jobId : ""
                        );
                        setFieldValue("shiftSkills", []);
                      }
                      setShiftType(e.target.value);
                    }}
                    sx={{ display: "flex", flexDirection: "row" }}
                  >
                    <FormControlLabel
                      value="jobs"
                      control={<Radio />}
                      label="Job role"
                      disabled={values.shiftPublished}
                    />
                    <FormControlLabel
                      value="skills"
                      control={<Radio />}
                      label="Skills"
                      disabled={values.shiftPublished}
                    />
                  </RadioGroup>
                  {shiftType === "skills" ? (
                    <Tooltip
                      title="All default skill levels are set in the Admin section"
                      arrow
                      slotProps={{
                        popper: {
                          sx: {
                            [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                              {
                                width: "150px",
                              },
                          },
                        },
                      }}
                    >
                      <InfoOutlinedIcon />
                    </Tooltip>
                  ) : null}
                </FormControl>
              </Box>
            )}

            {shiftType === "skills" ? (
              <SelectSkills
                values={values}
                setFieldValue={setFieldValue}
                getNumberOfStaff={getNumberOfStaff}
                resetMaxWorkersByLocationJob={resetMaxWorkersByLocationJob}
              />
            ) : (
              <Box width={"100%"}>
                <FilledFormControl>
                  <Autocomplete
                    options={filteredJobs ?? []}
                    getOptionLabel={(option) => option.name ?? ""}
                    value={
                      filteredJobs?.find(
                        (job: any) => job.id === values.jobId
                      ) ?? null
                    }
                    disabled={!values.locationId || values.shiftPublished}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        if (getNumberOfStaff) {
                          getNumberOfStaff(
                            newValue?.id.toString() ?? "",
                            values?.locationId,
                            new Date(values.startTime).toISOString(),
                            new Date(values.endTime).toISOString(),
                            []
                          );
                        }
                        setFieldValue("jobId", newValue.id ?? "");
                        setFieldValue("hexColor", newValue.hexColor ?? "");
                      } else {
                        setFieldValue("jobId", "");
                        setFieldValue("hexColor", "");
                      }
                    }}
                    renderInput={(params) => {
                      return (
                        <Text
                          {...params}
                          variant="filled"
                          label="Job Role"
                          disabled={!values.locationId || values.shiftPublished}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: values.jobId && (
                              <JobColor
                                color={`#${
                                  (
                                    filteredJobs?.find(
                                      (job: any) => job.id === values.jobId
                                    ) || { hexColor: "ffffff" }
                                  ).hexColor
                                }`}
                              />
                            ),
                          }}
                        />
                      );
                    }}
                    renderOption={(props, option) => (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          gap: "5px",
                        }}
                        component={"li"}
                        {...props}
                      >
                        <JobColor color={`#${option.hexColor}`} />
                        <Typography>{option.name}</Typography>
                      </Box>
                    )}
                  />
                  <Typography
                    variant="caption"
                    sx={{
                      marginTop: "2px",
                      color:
                        maxWorkersByJobLocation.data === 0 ? "red" : "auto",
                    }}
                  >
                    {maxWorkersByJobLocation.status === "pending" ? (
                      <CircularProgress size={20} />
                    ) : maxWorkersByJobLocation.status === "fulfilled" &&
                      values.jobId ? (
                      maxWorkersByJobLocation.data + " worker(s) have this job"
                    ) : (
                      ""
                    )}
                  </Typography>
                </FilledFormControl>
              </Box>
            )}

            <Box width={"100%"} mr={1} mt={2} mb={2}>
              <FilledFormControl>
                <Text
                  type="text"
                  variant="filled"
                  name="note"
                  id="note"
                  label="Note (optional)"
                  InputLabelProps={{ shrink: true }}
                  placeholder="Worker will see this note when they apply for this shift"
                  multiline
                  rows={2}
                  value={values.shiftNote}
                  disabled={isSubmitting || values.shiftPublished}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue("shiftNote", e.target.value);
                  }}
                  error={values.shiftNote.length > 250}
                  helperText={
                    values.shiftNote.length > 250
                      ? "Note should be less than 250 characters"
                      : ""
                  }
                />
              </FilledFormControl>
              <Box display="flex" justifyContent="flex-end">
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    lineHeight: "19px",
                    fontFamily: "Roboto",
                    color: "#666666",
                  }}
                >{`${values.shiftNote.length} / 250`}</Typography>
              </Box>
            </Box>
            {/* </Box> */}
            {/* Edit Button */}
            <EditActionButtons
              handleClose={handleClose}
              isValid={isValid}
              dirty={dirty}
              isSubmitting={isSubmitting}
              isDisabled={
                (shiftType === "skills"
                  ? values.shiftSkills.length === 0
                  : values.jobId === "" || values.jobId === undefined) ||
                (values.shiftPurposeId === 0 && isShiftPurposeEnabled)
              }
            />
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default EditShiftDialogForm;
