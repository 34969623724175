// TODO - Type 'any' needs to be fixed.
import AddIcon from "@mui/icons-material/Add";
import DateRangeIcon from "@mui/icons-material/DateRange";
import FilterListIcon from "@mui/icons-material/FilterList";
import SendIcon from "@mui/icons-material/Send";
import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";
import addHours from "date-fns/addHours";
import format from "date-fns/format";
import { useContext, useEffect, useState } from "react";
import React, { Dispatch, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FilterContext } from "../../filters/filter-context";
import { resetPublishDraftShiftsState } from "../store/scheduler-slice";
import {
  changeDayNoCache,
  changeWeeklyAvailability,
} from "../store/scheduler-slice";
import { changeWeekNoCache } from "../store/scheduler-slice";
import SwitchViews from "./switch-views";
import WeekNavigator from "./week-navigator";

interface ICalendarHeaderProps {
  checked: boolean;
  toggleScheduler: boolean;
  collapseFilter: () => void;
  type?: any;
  openCreateWeekShiftDialog: (date: any) => void;
  openCreateNewShiftDialog: (date: any) => void;
  openReviewPublishShiftDialog: (shiftIds: number[]) => void;
}

const CalendarHeader: React.FC<ICalendarHeaderProps> = ({
  checked,
  toggleScheduler,
  collapseFilter,
  type = null,
  openCreateWeekShiftDialog,
  openCreateNewShiftDialog,
  openReviewPublishShiftDialog,
}) => {
  const dispatch = useDispatch();
  const filterContext = useContext(FilterContext);
  if (!filterContext) {
    throw new Error("FilterAccordion must be used within a FilterProvider");
  }
  const { setFilterOpen } = filterContext;

  const [currentMonth, setCurrentMonth] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [enableCreateShift, setEnableCreateShift] = useState(false);
  const currentDate = useSelector(
    (state: any) => state.supervisor?.schedulerData?.currentDate
  );

  const isWeekView = useSelector((state: any) => state.supervisor?.weekView);
  const shiftBySkills = useSelector(
    (state: any) => state.user.userData.shiftBySkills
  );
  // const fetchInitialDataStatus = useSelector(
  //   (state: any) => state.supervisor?.fetchInitialData?.status
  // );
  const fetchFilterAndShiftDataStatus = useSelector(
      (state: any) => state.supervisor?.fetchFilterAndShiftData?.status
  );
    const value = useSelector(
        (state: any) => state.supervisor?.schedulerData?.selectedAvailabilityValue
    );
  const userRoles = useSelector((state: any) => state.user.userData.userRoles);

  const publishAndNotifyHandler = () => {
    dispatch(resetPublishDraftShiftsState());
    dispatch(openReviewPublishShiftDialog([]));
  };

  const handleForward = () => {
    if (isWeekView) {
      dispatch(changeWeekNoCache("forward"));
      if (type && value !== null) {
        dispatch(
          changeWeeklyAvailability({
            selection: "forward",
            shiftBySkills: shiftBySkills,
            type: type,
            id: value
          })
        );
      }
      return;
    }
    dispatch(changeDayNoCache("forward"));
    // dispatch(changeDayAvailability("forward"));
  };

  const handleBack = () => {
    if (isWeekView) {
      dispatch(changeWeekNoCache("back"));
      if (type && value !== null) {
        dispatch(
          changeWeeklyAvailability({
            selection: "back",
            shiftBySkills: shiftBySkills,
            type: type,
            id: value
          })
        );
      }
      return;
    }
    dispatch(changeDayNoCache("back"));
  };

  const handleToday = () => {
    dispatch(changeWeekNoCache("today"));
    if (type && value !== null) {
      dispatch(
        changeWeeklyAvailability({
          selection: "today",
          shiftBySkills: shiftBySkills,
          type: type,
          id: value
        })
      );
    }
  };

  const handleCalendarClick = (e: any) => {
    dispatch(changeWeekNoCache(new Date(e)));
    if (type && value !== null) {
      dispatch(
        changeWeeklyAvailability({
          selection: new Date(e),
          shiftBySkills: shiftBySkills,
          type: type,
          id: value
        })
      );
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (currentDate) {
      const dt = format(new Date(currentDate), "MMM yyyy");
      setCurrentMonth(dt.toString());
      setIsLoading(false);
    }
  }, [currentDate]);

  useEffect(() => {
    if (fetchFilterAndShiftDataStatus === "fulfilled") {
      setEnableCreateShift(true);
    }
  }, [fetchFilterAndShiftDataStatus]);

  return (
    <Box
      sx={{
        height: "56.5px",
        minWidth: "1301px",
        padding: "12px 20px 12px 12px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginLeft: "4px",
      }}
    >
      <Box display="flex" alignItems="center">
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              flexDirection: "row",
            }}
          >
            <Skeleton
              variant="rounded"
              width="32px"
              height="32px"
              sx={{
                marginLeft: "12px",
                borderRadius: "4px",
              }}
            />
            <Skeleton
              variant="rounded"
              width={64}
              height={32}
              sx={{
                borderRadius: "4px",
                marginLeft: "24px",
              }}
            />
            <Skeleton
              variant="rounded"
              width={32}
              height={32}
              sx={{
                marginLeft: "24px",
                borderRadius: "4px",
              }}
            />
            <Skeleton
                variant="rounded"
                width={32}
                height={32}
                sx={{
                  borderRadius: "4px",
                  marginLeft: "24px",
                }}
            />
            <Skeleton
              variant="rounded"
              width={192}
              height={32}
              sx={{
                borderRadius: "4px",
                marginLeft: "24px",
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "282px"
              }}
            >
              <IconButton
                  aria-label="scheduler-filter"
                  onClick={() => {
                    setFilterOpen((prevState) => !prevState);
                    collapseFilter();
                  }}
                  sx={{
                    backgroundColor: checked ? "#2F4D8B0A" : "none",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: "8px",
                    borderRadius: "4px",
                    border: 0,
                    cursor: "pointer",
                    height: "36px",
                    width: "36px",
                    "&:hover": {
                      backgroundColor: "#2F4D8B0A"
                    }
                  }}
              >
                <FilterListIcon
                    sx={{
                      width: "20px",
                      height: "20px",
                    }}
                />
              </IconButton>
              {toggleScheduler && (
                <Button
                  variant="outlined"
                  onClick={handleToday}
                  sx={{
                    height: "36px",
                    border: "1px solid rgba(0,0,0,0.08)",
                    borderColor: "rgba(0,0,0,0.08) !important",
                    color: "rgba(0,0,0,0.6)",
                    marginLeft: "24px",
                  }}
                >
                  TODAY
                </Button>
              )}
              {!toggleScheduler && (
                <Button
                  variant="outlined"
                  onClick={handleToday}
                  sx={{
                    height: "36px",
                    border: "1px solid rgba(0,0,0,0.08)",
                    borderColor: "rgba(0,0,0,0.08) !important",
                    color: "rgba(0,0,0,0.6)",
                    marginLeft: "24px",
                  }}
                >
                  TODAY
                </Button>
              )}
              {!toggleScheduler && <SwitchViews isWeekView={isWeekView} />}
            </Box>
            <WeekNavigator
                handleBack={handleBack}
                handleForward={handleForward}
                handleCalendarClick={handleCalendarClick}
                date={currentDate}
                isWeekView={isWeekView}
            />
          </Box>
        )}
      </Box>
      {isLoading ? (
        <Skeleton
          variant="rounded"
          width={85}
          height={32}
          sx={{
            borderRadius: "4px",
            marginRight: "16px",
            marginLeft: "16px",
          }}
        />
      ) : (
        <Typography
          sx={{
            fontFamily: "Roboto",
            fontSize: "20px",
            fontWeight: 400,
            color: "#000000",
            lineHeight: "28px",
            marginRight: "16px",
            marginLeft: "16px",
            minWidth: "85px",
          }}
        >
          {currentMonth}
        </Typography>
      )}
      {!toggleScheduler && !userRoles.includes("ReadOnly") ? (
        <Box display="flex" alignItems="center">
          {!enableCreateShift ? (
            <Skeleton
              variant="rounded"
              width={130}
              height={32}
              sx={{
                borderRadius: "4px",
                marginLeft: "8px",
              }}
            />
          ) : (
            <Button
              variant="text"
              startIcon={<DateRangeIcon />}
              onClick={() => openCreateWeekShiftDialog(new Date(currentDate))}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "nowrap",
                height: "36px",
                marginRight: "8px",
                marginLeft: "8px",
                lineHeight: "18px",
                minWidth: "130px",
              }}
              color="primary"
            >
              Copy Shifts
            </Button>
          )}

          {!enableCreateShift ? (
            <Skeleton
              variant="rounded"
              width={110}
              height={32}
              sx={{
                borderRadius: "4px",
                marginLeft: "8px",
              }}
            />
          ) : (
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => openCreateNewShiftDialog(addHours(new Date(), 24))}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "nowrap",
                height: "36px",
                marginRight: "8px",
                marginLeft: "8px",
                lineHeight: "18px",
                minWidth: "110px",
              }}
              color="primary"
            >
              Shift
            </Button>
          )}

          {!enableCreateShift ? (
            <Skeleton
              variant="rounded"
              width={210}
              height={32}
              sx={{
                borderRadius: "4px",
                marginLeft: "8px",
              }}
            />
          ) : (
            <Button
              variant="contained"
              startIcon={<SendIcon />}
              onClick={() => publishAndNotifyHandler()}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "nowrap",
                height: "36px",
                marginRight: "8px",
                marginLeft: "8px",
                lineHeight: "18px",
                minWidth: "210px",
              }}
              color="primary"
            >
              Publish and Notify
            </Button>
          )}
        </Box>
      ) : (
        <Box sx={{
          width: "410px" // To align header at center
        }} />
      )}
    </Box>
  );
};

export default CalendarHeader;
