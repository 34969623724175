
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import startOfDay from "date-fns/startOfDay";

import { ATTENDANCE_REPORT_FILTERS } from "../../../assets/constants";
import { setSupervisorRateLimitError } from "../../../features/user/user-slice";
import axios from "../../../utils/api-client";
import { setEndReducer } from "../reducers/setEndReducer";
import { setStartReducer } from "../reducers/setStartReducer"
import { AttendanceReportsStateTypes, ShiftWorkerHoursModel } from "./attendance-reports-types";


const initialState: AttendanceReportsStateTypes = {
    currentDate: startOfDay(new Date()).toISOString(),
    dayReport: [],
    getDayReport: {
        status: "idle",
        errorMessage: "",
    },
    workCenterReport: [],
    getWorkCenterReport: {
        status: "idle",
        errorMessage: "",
    },
    startDate: new Date(
        new Date().setMonth(new Date().getMonth() - 1)
    ).toISOString(),
    endDate: new Date().toISOString(),
    // allWorkers: [],
    fetchWorkersData: {
        status: "idle",
        errorMessage: "",
    },
    attendanceReportFilters: {
        locations: [],
        managers: [],
        workerTypes: [],
        attendanceStatuses: [],
    },
    getAttendanceReportFilters: {
        status: "idle",
        errorMessage: "",
    },

}

export const getDayReport = createAsyncThunk(
    "worker/getDayReport",
    async (payload, { fulfillWithValue, rejectWithValue, getState, dispatch }) => {
        const date = (getState() as any).report.currentDate;

        try {
            const res = await axios.get(
                `${process.env.REACT_APP_API_END_POINT}/Reports/GetReportDayWorkers`,
                {
                    params: {
                        dateOfReport: date,
                    },
                }
            );

            if (res.status === 200) {
                return fulfillWithValue(res.data);
            }
            if (res.status === 204) {
                return fulfillWithValue([]);
            }
            return rejectWithValue("An error occurred");
        } catch (error: any) {
            // console.log(error);
            // if (error.message === "Request failed with status code 404")
            //     return rejectWithValue({ error: "No data found" });
            // return rejectWithValue({ error: error.response.data });
            const errorResponse = error?.response.data;
            errorResponse.status = error?.response?.status;
            try{
                if (errorResponse.status === 429) {
                    dispatch(setSupervisorRateLimitError());
                    return rejectWithValue(setSupervisorRateLimitError());
                } else if (errorResponse.status === 404) {
                    return rejectWithValue({ error: "No data found" });
                }
                return rejectWithValue({ error: errorResponse });
            } catch{
                return rejectWithValue("An error occurred");
            }
        }
    }
);

// export const markAttendance = createAsyncThunk(
//   "worker/absent",
//   async (payload, thunkAPI) => {
//     console.log('payload to mark attendance: ', payload)
//     try {
//       console.log('marking absent/present with: ', payload)
//       const res = await fetch(`${process.env.REACT_APP_API_END_POINT}/Workers/Absent`, {
//         method: 'post',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': 'Token ' + localStorage.getItem("token"),
//         },
//         body: JSON.stringify(payload?._data)
//       })

//       if (res.status === 200) {
//         console.log(res, ' is the response for absent/present')
//         return thunkAPI.fulfillWithValue(res.data);
//       }
//       if(res.status === 204) {
//         return thunkAPI.fulfillWithValue([])
//       }
//       return thunkAPI.rejectWithValue("An error occurred");
//     } catch (error) {
//       console.log(error);
//       if (error.message === "Request failed with status code 404")
//         return thunkAPI.rejectWithValue({ error: "No data found" });
//       return thunkAPI.rejectWithValue({ error: error.response.data });
//     }
//   }
// );

export const getWorkCenterReport = createAsyncThunk(
    "worker/getWorkCenterReport",
    async (payload, { fulfillWithValue, rejectWithValue, getState }) => {
        const start = (getState() as any).report.startDate;
        const end = (getState() as any).report.endDate;

        try {
            const res = await axios.get(
                `${process.env.REACT_APP_API_END_POINT}/Reports/GetReportWorkerHours`,
                {
                    params: {
                        dateOfReportStart: start,
                        dateOfReportEnd: end,
                    },
                }
            );

            if (res.status === 200) {
                return fulfillWithValue(res.data);
            }
            if (res.status === 204) {
                return fulfillWithValue([]);
            }
            return rejectWithValue("An error occurred");
        } catch (error: any) {
            // console.log(error);
            // if (error.message === "Request failed with status code 404")
            //     return rejectWithValue({ error: "No data found" });
            // return rejectWithValue({ error: error.response.data });
            const errorResponse = error?.response.data;
            errorResponse.status = error?.response?.status;
            try{
                if (errorResponse.status === 429) {
                    return rejectWithValue(setSupervisorRateLimitError());
                } else if (errorResponse.status === 404) {
                    return rejectWithValue({ error: "No data found" });
                }
                return rejectWithValue({ error: errorResponse });
            } catch{
                return rejectWithValue("An error occurred");
            }
        }
    }
);

export const getAttendanceReportFilters = createAsyncThunk(
    "worker/getAttendanceReportFilters",
    async (_,{ fulfillWithValue, rejectWithValue }) => {

        try {
            const res = await axios.post(
                `${process.env.REACT_APP_API_END_POINT}/FlexScheduler/GetFiltersForUser`,
                ATTENDANCE_REPORT_FILTERS
            );

            if (res.status === 200) {
                return fulfillWithValue(res.data);
            }
            return rejectWithValue("An error occurred");
        } catch (error: any) {
            const errorResponse = error?.response.data;
            errorResponse.status = error?.response?.status;
            try{
                if (errorResponse.status === 429) {
                    return rejectWithValue(setSupervisorRateLimitError());
                } else if (errorResponse.status === 404) {
                    return rejectWithValue({ error: "No data found" });
                }
                return rejectWithValue({ error: errorResponse });
            } catch{
                return rejectWithValue("An error occurred");
            }
        }
    }
);
// export const getAllWorkers = createAsyncThunk(
//  "manageWorkers/skillLevels",
//  async (payload, { fulfillWithValue, rejectWithValue }) => {
//      try {
//          const getSkillLevels = await axios.get(
//              `${process.env.REACT_APP_API_END_POINT}/Skill/Levels`
//          );
//          return fulfillWithValue(getSkillLevels.data);
//      } catch (error) {
//          return rejectWithValue(error.message);
//      }
//  }
// );

// export const fetchWorkersData = createAsyncThunk(
//     "fetchWorkersData",
//     async (payload, { fulfillWithValue, rejectWithValue, getState }) => {
//         try {
//             const getAllWorkers = await axios.get(
//                 `${process.env.REACT_APP_API_END_POINT}/companies/workers`
//             );
//             if (getAllWorkers.status === 200) {
//                 return fulfillWithValue(getAllWorkers.data);
//             }
//             if (getAllWorkers.status === 204) {
//                 return fulfillWithValue([]);
//             }
//             return rejectWithValue("An Error Occured");
//         } catch (error: any) {
//             // console.error(error);
//             return rejectWithValue(error.message);
//         }
//     }
// );
export const ReportSlice = createSlice({
    name: "report",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(getDayReport.pending, (state, action) => {
            state.getDayReport.status = "pending";
        });
        builder.addCase(getDayReport.fulfilled, (state, action) => {
            let report = [];
            if (action.payload) {
                report = action.payload.map(
                    (item: ShiftWorkerHoursModel) =>
                    (item = {
                        ...item,
                        fullName: `${item.firstName} ${item.lastName}`,
                        id: item.workerId + item.rowId,
                    })
                );
            }
            state.dayReport = report;
            state.getDayReport.status = "fulfilled";
        });
        builder.addCase(getDayReport.rejected, (state, action:any) => {
            if (action.payload?.status === 429) {
                state.getDayReport.status = "error";
            } else{
                const error = action.payload?.error;
                if (error === "No data found") {
                    state.getDayReport.status = "error";
                    state.getDayReport.errorMessage = "There are no reports available .";
                } else {
                    state.getDayReport.status = "error";
                    state.getDayReport.errorMessage = error ?? "An error occurred.";
                }
            }
        });
        builder.addCase(getWorkCenterReport.pending, (state, action) => {
            state.getWorkCenterReport.status = "pending";
        });
        builder.addCase(getWorkCenterReport.fulfilled, (state, action) => {
            let report = [];
            if (action.payload) {
                report = action.payload.map(
                    (item: ShiftWorkerHoursModel) =>
                    (item = {
                        ...item,
                        fullName: `${item.firstName} ${item.lastName}`,
                        id: item.workerId + item.shiftId,
                    })
                );
            }
            state.workCenterReport = report;
            state.getWorkCenterReport.status = "fulfilled";
        });
        builder.addCase(getWorkCenterReport.rejected, (state, action:any) => {
            if (action.payload?.status === 429) {
                state.getWorkCenterReport.status = "error";
            } else{
                const error = action.payload?.error;
                if (error === "No data found") {
                    state.getWorkCenterReport.status = "error";
                    state.getWorkCenterReport.errorMessage =
                        "There are no reports available .";
                } else {
                    state.getWorkCenterReport.status = "error";
                    state.getWorkCenterReport.errorMessage = error ?? "An error occurred.";
                }
            }
        });
        builder.addCase(getAttendanceReportFilters.fulfilled, (state, action) => {
            state.attendanceReportFilters = action.payload;
            state.getAttendanceReportFilters.status = "fulfilled";
        });
        builder.addCase(getAttendanceReportFilters.rejected, (state, action) => {
            state.getAttendanceReportFilters.status = "error";
        });
        builder.addCase(getAttendanceReportFilters.pending, (state, action) => {
            state.getAttendanceReportFilters.status = "pending";
        });
        // builder.addCase(fetchWorkersData.fulfilled, (state, action) => {
        //     const allWorkers = action.payload;
        //     state.allWorkers = allWorkers;
        //     state.fetchWorkersData.status = "fulfilled";
        // });
        // builder.addCase(fetchWorkersData.pending, (state) => {
        //     state.fetchWorkersData.status = "pending";
        // });
        // builder.addCase(fetchWorkersData.rejected, (state, action) => {
        //     state.fetchWorkersData.status = "error";
        //     state.fetchWorkersData.errorMessage =
        //         action.payload ?? "An error occurred";
        // });
    },    
    reducers: {
        setStart: setStartReducer,
        setEnd: setEndReducer,
        resetWorkCenterReport: (state) => {
            state.workCenterReport = [];
        },
        setCurrentDate: (state, action) => {
            state.currentDate = action.payload;
        },
    },
});

export const { setStart, setEnd, resetWorkCenterReport, setCurrentDate } =
    ReportSlice.actions;

export default ReportSlice.reducer;

