import jwt_decode from "jwt-decode";
import React from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route } from "react-router-dom";

import { FetchToken, setUser } from "../../export/user";
import { setWorker } from "../../export/web-worker";

const PrivateRoute = ({
	component: Component,
	workerApp,
	authenticated,
	...rest
}: any) => {
	const dispatch = useDispatch();

	const workerToken = localStorage.getItem("worker-token");
	const workerRefreshToken = localStorage.getItem("worker-refreshToken");
	const token = localStorage.getItem("token");
	const refreshToken = localStorage.getItem("refreshToken");

	if (workerApp) {
		if (!authenticated) {
			if (workerToken && workerRefreshToken) {
				const { exp }: any = jwt_decode(workerToken);
				// console.log(exp , ' :exp and id: ', id)
				if (Date.now() / 1000 < exp) {
					dispatch(setWorker());
					return (
						<Route {...rest} render={(props) => <Component {...props} />} />
					);
				} else {
					// console.log('handing over control to FetchToken component', Component)
					return <FetchToken component={Component} workerApp={true} />;
				}
			} else {
				// console.log()
				return <Redirect to={{ pathname: "/wl" }} />;
			}
		} else {
			//console.log("AUTHENTICATED");
			return (
				<Route
					{...rest}
					render={(props) =>
						authenticated ? (
							<Component {...props} />
						) : (
							<Redirect to={{ pathname: "/wl" }} />
						)
					}
				/>
			);
		}
	} else {
		if (!authenticated) {
			// console.log("NOT AUTHENTICATED");
			// console.log(window.location, ' window.location')
			// console.log(window.location.pathname, ' window.location.pathname')
			// if (window.location.pathname === "/day-report") {
			// 	// console.log('pathname is /day-report')
			// 	localStorage.setItem(
			// 		"login-redirect",
			// 		window.location.href.replace(window.location.origin, "")
			// 	);
			// }
			if (token && refreshToken) {
				// console.log(
				// 	"not authenticated AND refresh / access token found",
				// 	jwt_decode(localStorage.getItem("token"))
				// );
				const decodedToken: any = jwt_decode(token);
				const attendMgmt = decodedToken["attendMgmt.enabled"];
				const swapAndCancelShift = decodedToken["ftShift.swapAndCancelEnabled"];
				const skillPriorityEnabled = decodedToken["skillPriority.Enabled"];
				const useObsoleteSkills =
					decodedToken["skillPriority.workerScore.useObsoleteSkill"];
				const jobSkillLevelMapping = decodedToken["jobSkill.skillLevelEnabled"];
				const workerSubTypeEnabled = decodedToken["workerSubType.enabled"];

				const {
					exp,
					userRoles,
					id,
					showHeatMap,
					showJobExpiry,
					minDurationHrs,
					maxDurationHrs,
					defaultDurationHrs,
					showCrossSkilling,
					shiftCancelReasonEnabled,
					customizedBrandingEnabled,
					certificationsEnabled,
					skillExpiryEnabled,
					skillInsightsEnabled,
					showWorkerProfile,
					showShiftPurpose,
					fullTimeSchedule,
					showShiftSwap,
					restrictFlexHours,
					schedulerOffsetEnabled,
					firstName,
					lastName,
					email,
					company,
					companyId,
					shiftBySkillsMinSkillLevel,
					shiftBySkillsEnabled,
					assignShiftToWorkerEnabled,
					weekStartDay,
					weekStartHour,
					skillLevelRequirementsEnabled,
				}: any = jwt_decode(token);

				//console.log("PR", jwt_decode(token));
				if (Date.now() / 1000 < exp) {
					dispatch(
						setUser({
							userRoles,
							id,
							showHeatMap,
							showJobExpiry,
							showCrossSkilling,
							shiftCancelReasonEnabled,
							skillPriorityEnabled,
							useObsoleteSkills,
							customizedBrandingEnabled,
							certificationsEnabled,
							skillExpiryEnabled,
							skillInsightsEnabled,
							minDurationHrs,
							maxDurationHrs,
							defaultDurationHrs,
							showWorkerProfile,
							showShiftPurpose,
							fullTimeSchedule,
							showShiftSwap,
							restrictFlexHours,
							schedulerOffsetEnabled,
							firstName,
							lastName,
							username: email,
							company,
							companyId,
							attendMgmt,
							swapAndCancelShift,
							shiftBySkillsMinSkillLevel,
							shiftBySkillsEnabled,
							assignShiftToWorkerEnabled,
							weekStartDay,
							weekStartHour,
							jobSkillLevelMapping,
							workerSubTypeEnabled,
							skillLevelRequirementsEnabled,
						})
					);

					return (
						<Route {...rest} render={(props) => <Component {...props} />} />
					);
				} else {
					//console.log("TOKEN EXPIRED. REFRESHING.");
					//return <Redirect to={{ pathname: "/login" }} />;
					return <FetchToken component={Component} workerApp={false} />;
				}
			} else {
				// console.log("not authenticated and NO refresh / access token found");
				return <Redirect to={{ pathname: "/login" }} />;
			}
		} else {
			return (
				<Route
					{...rest}
					render={(props) =>
						authenticated ? (
							<Component {...props} />
						) : (
							<Redirect to={{ pathname: "/login" }} />
						)
					}
				/>
			);
		}
	}
};

export default PrivateRoute;
