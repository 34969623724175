/* eslint-disable no-mixed-spaces-and-tabs */
import LoadingButton from "@mui/lab/LoadingButton";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	MenuItem,
	TextField,
	Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import useGatAdminConfigData from "../../../../../controllers/use-gat-admin-config-data";
import {
	addWorkerSubType,
	editWorkerSubType,
} from "../../../../../store/gat-admin-slice";

interface WorkerType {
	id: number;
	text: string;
}

interface WorkerSubType {
	id: number;
	name: string;
	description: string;
	workerTypeId: number;
	workerTypeName?: string;
}

interface FormValues {
	id?: number;
	workerSubType: string;
	description: string;
	workerType: number | string;
}

const validationSchema = Yup.object({
	workerSubType: Yup.string()
		.required("Worker Sub Type is required")
		.max(30, "Worker Sub Type cannot exceed 30 characters"),
	description: Yup.string()
		.required("Description is required")
		.max(150, "Description cannot exceed 150 characters"),
	workerType: Yup.string().required("Worker Type is required"),
});

interface WorkerSubTypeDialogProps {
	open: boolean | number;
	onClose: () => void;
	type: "add" | "edit";
	id?: number;
}

const WorkerSubTypeDialog = ({
	open,
	onClose,
	type,
	id,
}: WorkerSubTypeDialogProps) => {
	const { workerTypes, companyId, workerSubTypes } = useGatAdminConfigData();
	const dispatch = useDispatch();

	const handleSubmit = (
		values: FormValues,
		{ setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
	) => {
		const selectedWorkerType = workerTypes.find(
			(workerType: WorkerType) => workerType.id === values.workerType
		);

		const workerSubType = {
			name: values.workerSubType,
			description: values.description,
			workerTypeId: values.workerType as number,
			companyId,
			workerTypeName: selectedWorkerType?.text,
		};

		if (type === "add") {
			dispatch(addWorkerSubType(workerSubType));
		} else {
			dispatch(editWorkerSubType({ ...workerSubType, id: values.id }));
		}
		setSubmitting(true);
	};

	const initialEditValues = id
		? workerSubTypes.find(
				(workerSubType: WorkerSubType) => workerSubType.id === id
		  )
		: null;

	const initialValues: FormValues =
		type === "add"
			? {
					workerSubType: "",
					description: "",
					workerType: 3,
			  }
			: {
					id: initialEditValues?.id,
					workerSubType: initialEditValues?.name ?? "",
					description: initialEditValues?.description ?? "",
					workerType: 3,
			  };

	return (
		<Dialog
			open={Boolean(open)}
			onClose={onClose}
			aria-describedby='alert-dialog-slide-description'
			sx={{
				"& .MuiDialog-paper": {
					width: "900px",
					maxWidth: "900px",
				},
			}}
		>
			<DialogTitle sx={{ padding: "45px 45px 24px" }}>
				{type === "add" ? "Create Worker Sub Type" : "Edit Worker Sub Type"}
			</DialogTitle>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					dirty,
					isValid,
					isSubmitting,
				}) => (
					<Form>
						<DialogContent
							sx={{
								padding: "0px 45px 24px",
							}}
						>
							<TextField
								variant='filled'
								fullWidth
								margin='normal'
								name='workerSubType'
								label='Worker Sub Type*'
								inputProps={{ maxLength: 30 }}
								value={values.workerSubType}
								onChange={handleChange}
								onBlur={handleBlur}
								error={touched.workerSubType && Boolean(errors.workerSubType)}
								helperText={touched.workerSubType && errors.workerSubType}
							/>

							<TextField
								variant='filled'
								fullWidth
								margin='normal'
								name='description'
								label='Description*'
								multiline
								rows={4}
								inputProps={{ maxLength: 150 }}
								value={values.description}
								onChange={handleChange}
								onBlur={handleBlur}
								error={touched.description && Boolean(errors.description)}
								helperText={touched.description && errors.description}
							/>

							{/* <TextField
								variant='filled'
								fullWidth
								margin='normal'
								select
								name='workerType'
								label='Worker Type'
								value={values.workerType}
								onChange={handleChange}
								onBlur={handleBlur}
								error={touched.workerType && Boolean(errors.workerType)}
								helperText={touched.workerType && errors.workerType}
							>
								{workerTypes.map((workerType: { id: number; text: string }) => (
									<MenuItem key={workerType.id} value={workerType.id}>
										{workerType.text}
									</MenuItem>
								))}
							</TextField> */}
							<Box
								sx={{
									backgroundColor: "#F5F5F5",
									width: "100%",
									marginTop: "16px",
									padding: "8px",
									borderRadius: "4px",
								}}
							>
								<Typography variant='h6'>Worker Type</Typography>
								<Typography variant='body1'>Flex</Typography>
							</Box>
						</DialogContent>

						<DialogActions
							sx={{
								padding: "33px 45px",
							}}
						>
							<Button onClick={onClose} color='primary'>
								CANCEL
							</Button>
							<LoadingButton
								type='submit'
								variant='contained'
								color='primary'
								sx={{ backgroundColor: "#2E4374" }}
								disabled={!dirty || !isValid}
								loading={isSubmitting}
							>
								{type === "add" ? "CREATE" : "SAVE"}
							</LoadingButton>
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default WorkerSubTypeDialog;
