import Box from "@mui/material/Box";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { MyScheduler } from "../../../export/scheduler";
import AppliedFilters from "../../components/filters/applied-filters";
import {
  JOB_ROLES,
  LOCATIONS,
  PUBLISHED_BY,
  SHIFT_BY,
  SKILLS,
  STATUS,
  WORK_CENTERS,
} from "../../components/filters/constants";
import { FilterContext } from "../../components/filters/filter-context";
import { setFilteredShifts } from "../../components/scheduler/store/scheduler-slice";
import { Shift } from "../../components/scheduler/store/types";

const APPLIED_FILTERS_HEIGHT = 57;

const WilyaScheduler = ({ ...props }: any) => {
  const dispatch = useDispatch();
  const shiftsData = useSelector(
    (state: any) => state.supervisor?.schedulerData?.shiftsData
  );
  const filterContext = useContext(FilterContext);
  if (!filterContext) {
    throw new Error("FilterAccordion must be used within a FilterProvider");
  }

  const { filterAccordionHeight, appliedFilters, setFilterOpen } = filterContext;

  useEffect(() => {
    // Hide filter by default
    setFilterOpen(false);
  }, [setFilterOpen]);

  useEffect(() => {
    if (shiftsData.length >= 0) {
      const getWorkCenterIdsByLocationId = (locationId: number) => {
        const workCenters = props.filters.find(
          (filter: { name: string }) => filter.name === WORK_CENTERS
        )?.data;
        if (workCenters) {
          return workCenters
            .filter(
              (workCenter: { locationId: number }) =>
                workCenter.locationId === locationId
            )
            .map((workCenter: { id: any }) => workCenter.id);
        }
        return "";
      };

      const filteredShiftsByJob = appliedFilters
        .find((appliedFilter) => appliedFilter.type === JOB_ROLES)
        ?.data.map((job: any) => job.id);

      const filteredShiftsBySkill = appliedFilters
        .find((appliedFilter) => appliedFilter.type === SKILLS)
        ?.data.map((skill: any) => skill.id);

      const allWorkCentersFromLocations = appliedFilters
        .find((appliedFilter) => appliedFilter.type === LOCATIONS)
        ?.data.map((location: any) => getWorkCenterIdsByLocationId(location.id))
        .flat();

      const filteredShiftsByWorkCenter = appliedFilters
        .find((appliedFilter) => appliedFilter.type === WORK_CENTERS)
        ?.data.map((workCenter: any) => workCenter.id);

      const filteredShiftsByStatus = appliedFilters
        .find((appliedFilter) => appliedFilter.type === STATUS)
        ?.data.map((status: any) => status.id);

      const filteredShiftsByPublishedBy = appliedFilters
        .find((appliedFilter) => appliedFilter.type === PUBLISHED_BY)
        ?.data.map((publishedBy: any) => publishedBy.id);

      const filteredShiftsByShiftBy = appliedFilters
        .find((appliedFilter) => appliedFilter.type === SHIFT_BY)
        ?.data.map((shiftBy: any) => shiftBy.id);

      const filteredShifts = shiftsData.filter((shift: Shift) => {
        let includeShift = true;

        // Union condition for job and skills
        if (filteredShiftsByJob && filteredShiftsBySkill) {
          includeShift =
            filteredShiftsByJob.includes(shift.jobId) ||
            shift.shiftSkills.some((skill: { skillId: any }) =>
              filteredShiftsBySkill.includes(skill.skillId)
            );
        } else {
          if (
            filteredShiftsByJob &&
            !filteredShiftsByJob.includes(shift.jobId)
          ) {
            includeShift = false;
          }
          if (
            filteredShiftsBySkill &&
            !shift.shiftSkills.some((skill: { skillId: any }) =>
              filteredShiftsBySkill.includes(skill.skillId)
            )
          ) {
            includeShift = false;
          }
        }

        // Intersection conditions for other filters
        if (
          includeShift &&
          allWorkCentersFromLocations &&
          !allWorkCentersFromLocations.includes(shift.workCenterId)
        ) {
          includeShift = false;
        }
        if (
          includeShift &&
          filteredShiftsByWorkCenter &&
          !filteredShiftsByWorkCenter.includes(shift.workCenterId)
        ) {
          includeShift = false;
        }
        if (
          includeShift &&
          filteredShiftsByStatus &&
          !filteredShiftsByStatus.includes(shift.isPublished ? 2 : 1)
        ) {
          includeShift = false;
        }
        if (
          includeShift &&
          filteredShiftsByPublishedBy &&
          !filteredShiftsByPublishedBy.includes(shift.createdBy)
        ) {
          includeShift = false;
        }
        if (
          includeShift &&
          filteredShiftsByShiftBy &&
          !filteredShiftsByShiftBy.includes(
            shift.shiftSkills.length > 0 ? 2 : 1
          )
        ) {
          includeShift = false;
        }

        return includeShift;
      });

      dispatch(setFilteredShifts(filteredShifts));
    }
  }, [dispatch, shiftsData, appliedFilters, props.filters]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "15px",
        minHeight: `${filterAccordionHeight}px`,
        flex: 1,
      }}
    >
      <AppliedFilters />
      <MyScheduler
        {...props}
        filterAccordionHeight={filterAccordionHeight - APPLIED_FILTERS_HEIGHT}
      />
    </Box>
  );
};
export default WilyaScheduler;
