import { format } from "date-fns";
import { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { formatDate } from "../../../utils/helpers";
import { Filter } from "../../filters/filter-accordion/types";
import { FilterContext } from "../../filters/filter-context";
import {
	getDailyActivityReport,
	setSelectedDayKPI,
} from "../store/worker-activity-slice";
import {
	ColumnSelector,
	Shift,
	Skill,
	Worker,
} from "../store/worker-activity-slice-types";
import { useWorkerActivityData } from "./use-worker-activity-data";

export const useDayActivityController = () => {
	const { currentDate: initialDate, dailyActivityReportData, workerActivityFilters, workerActivityFiltersStatus } =
		useWorkerActivityData();

	const [columnSelector, setColumnSelector] =
		useState<ColumnSelector[]>(DEFAULT_COLUMNS);
	const [currentDate, setCurrentDate] = useState<string>(initialDate);
	const [search, setSearch] = useState<string>("");
	const [allFilters, setAllFilters] = useState<Filter[]>([]);
	const dispatch = useDispatch();
	const filterContext = useContext(FilterContext);
	if (!filterContext) {
		throw new Error("FilterAccordion must be used within a FilterProvider");
	}

	const { appliedFilters, open, setFilterOpen } = filterContext;

	const searchTerm = useMemo(() => search.toLowerCase(), [search]);

	const mapWorkerToShiftData = (item: Worker) => {
		if (item.shifts.length > 0) {
			return item.shifts.map((shift: Shift) => ({
				id: `${item.workerId}-${shift.shiftId}`,
				workerId: item.workerId,
				workerName: `${item.firstName} ${item.lastName}`,
				isShiftAvailable: true,
				...shift,
			}));
		}

		return [
			{
				id: `${item.workerId}`,
				workerId: item.workerId,
				workerName: `${item.firstName} ${item.lastName}`,
				isShiftAvailable: false,
				...DEFAULT_SHIFT,
			},
		];
	};

	const tableData = useMemo(
		() => dailyActivityReportData.flatMap(mapWorkerToShiftData),
		[dailyActivityReportData]
	);

	const getSearchableFields = (
		item: Shift & { workerName: string; isShiftAvailable: boolean }
	) => {
		const baseFields = [
			item.workerName,
			item.shiftLocationName,
			item.shiftWorkcenterName,
			item.publishedByName,
			item.jobName,
			item.skills
				? item.skills.map((skill: Skill) => skill.skillName).join(", ")
				: "",
			statusObj[item.attendanceStatus?.toLowerCase()]?.title || "",
			item.supervisorComments,
		];

		if (item.isShiftAvailable) {
			const shiftDate = formatDate({
				dateStr: item.shiftStartDateTime,
				formatType: "NUMERIC_DATE",
			});
			const shiftTime = formatDate({
				dateStr: item.shiftStartDateTime,
				formatType: "TIME_RANGE",
				endDateStr: item.shiftEndDateTime,
			});
			return [...baseFields, shiftTime, shiftDate];
		}

		return [...baseFields, "", ""];
	};

	const filteredData = useMemo(
		() =>
			{
				let filteredResult = tableData
				if (appliedFilters.length > 0) {
					filteredResult = filteredResult.filter((item: Shift) => {
						return appliedFilters.every((filter) => {
							switch (filter.type) {
								case "Shift Location":
									return filter.data.some((location) => location?.name === item?.shiftLocationName);
								case "Workcenter":
									return filter.data.some((workcenter) => workcenter?.name === item?.shiftWorkcenterName);
								case "Published By":
									return filter.data.some((manager) => manager?.name === item?.publishedByName);
								case "Attendance Status":
									return filter.data.some((attendanceStatus) => attendanceStatus?.name === statusObj[item?.attendanceStatus as keyof typeof statusObj]?.title);
								default:
									return true;
							}
						});
					});
				}
				return filteredResult.filter((item) =>
					getSearchableFields(item)
						.map((field) => (field ?? "").toLowerCase())
						.some((field) => field.includes(searchTerm))
				);
			},
		[tableData, searchTerm, appliedFilters]
	);

	const exportData = () => {
		const data = filteredData.map((item) => {
			const baseExport = {
				"Worker Name": item.workerName,
				"Shift Date": "-",
				"Shift Time": "-",
				"Shift Location": "-",
				Workcenter: "-",
				"Published By": "-",
				"Job Role/Skill": "-",
				"Shift Attendance": "-",
				"Supervisor Comments": "-",
			};

			if (!item.isShiftAvailable) return baseExport;

			const shiftTime = formatDate({
				dateStr: item.shiftStartDateTime,
				formatType: "TIME_RANGE",
				endDateStr: item.shiftEndDateTime,
			});

			return {
				...baseExport,
				"Shift Date": formatDate({
					dateStr: item.shiftStartDateTime,
					formatType: "NUMERIC_DATE",
				}),
				"Shift Time": shiftTime,
				"Shift Location": item.shiftLocationName,
				Workcenter: item.shiftWorkcenterName,
				"Published By": item.publishedByName,
				"Job Role/Skill":
					item.jobName ||
					item.skills?.map((skill) => skill.skillName).join(", ") ||
					"-",
				"Shift Attendance":
					statusObj[item.attendanceStatus?.toLowerCase()]?.title || "-",
				"Supervisor Comments": item.supervisorComments || "-",
			};
		});
		// sort by worker name
		data.sort((a, b) => a["Worker Name"].localeCompare(b["Worker Name"]));
		return data;
	};

	useEffect(() => {
		if (currentDate) {
			dispatch(setSelectedDayKPI("ALL"));
			dispatch(getDailyActivityReport(currentDate));
		}
	}, [currentDate, dispatch]);

	useEffect(() => {
		if (workerActivityFiltersStatus === "fulfilled") {
			const newFilters: Filter[] = [];
			if (workerActivityFilters?.locations?.length > 0) {
				newFilters.push({
					id: 1,
					name: "Shift Location",
					data: workerActivityFilters.locations,
					reducedData: workerActivityFilters.locations,
					searchedData: workerActivityFilters.locations,
				});
			}
			if (workerActivityFilters?.workCenters?.length > 0) {
				newFilters.push({
					id: 2,
					name: "Workcenter",
					data: workerActivityFilters.workCenters,
					reducedData: workerActivityFilters.workCenters,
					searchedData: workerActivityFilters.workCenters,
				});
			}
			if (workerActivityFilters?.managers?.length > 0) {
				newFilters.push({
					id: 3,
					name: "Published By",
					data: workerActivityFilters.managers,
					reducedData: workerActivityFilters.managers,
					searchedData: workerActivityFilters.managers,
				});
			}
			if (workerActivityFilters?.attendanceStatuses?.length > 0) {
				newFilters.push({
					id: 4,
					name: "Attendance Status",
					data: workerActivityFilters.attendanceStatuses,
					reducedData: workerActivityFilters.attendanceStatuses,
					searchedData: workerActivityFilters.attendanceStatuses,
				});
			}
			setAllFilters(newFilters);
		}
	}, [workerActivityFiltersStatus, workerActivityFilters, setFilterOpen]);

	return [
		{ currentDate, filteredData, exportData, search, columnSelector, open, allFilters },
		{ setCurrentDate, setSearch, setColumnSelector, setFilterOpen, setAllFilters },
	] as const;
};

const DEFAULT_COLUMNS: ColumnSelector[] = [
	{ headerName: "Worker Name", field: "workerName", checked: true },
	{ headerName: "Shift Start Date", field: "shiftStartDate", checked: true },
	{ headerName: "Shift Hours", field: "shiftHours", checked: true },
	{ headerName: "Shift Location", field: "shiftLocation", checked: true },
	{ headerName: "Workcenter", field: "workcenter", checked: true },
	{ headerName: "Published By", field: "publishedByName", checked: true },
	{ headerName: "Job Role/Skill", field: "jobRoleSkills", checked: true },
	{ headerName: "Shift Attendance", field: "attendance", checked: true },
	{
		headerName: "Supervisor Comments",
		field: "supervisorComments",
		checked: true,
	},
];

const DEFAULT_SHIFT: Shift = {
	shiftId: "",
	shiftStartDateTime: "",
	shiftEndDateTime: "",
	shiftLocationName: "",
	shiftWorkcenterName: "",
	publishedByName: "",
	jobName: "",
	skills: [],
	attendanceStatus: "",
	supervisorComments: "",
};

interface IStatusObjType {
	[key: string]: {
		title: string;
	};
}

const statusObj: IStatusObjType = {
	p: {
		title: "Present",
	},
	a: {
		title: "No Show",
	},
	t: {
		title: "Tardy",
	},
	e: {
		title: "Excused Absence",
	},
	x: {
		title: "Excused Tardy",
	},
};
