/* eslint-disable no-mixed-spaces-and-tabs */
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { gridClasses } from "@mui/x-data-grid-pro-6";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
	ColumnCell,
	ColumnEmployeeCell,
	ColumnGroupJobHeader,
	ColumnHeader,
	ColumnNoSkillHeader,
} from "../../../../../export/cross-skilling";
import SortedDataGridPro6 from "../../../../../ts/design/sorted-datagrid-pro-6";
import { formatSkillScore } from "../../../../../ts/utils/helpers";
import useCrossSkillTableController from "../../controllers/use-cross-skill-table-controller";
import {
	createSkillColumn,
	createSkillColumnWithoutJobs,
	findOtherSkills,
	skillsWithoutJobs,
} from "./utils";

const COLUMN_CONFIGS = {
	companyEmployeeId: {
		field: "companyEmployeeId",
		headerName: "Worker ID",
		valueGetter: (params) => params.row.companyEmployeeId,
		sortComparator: (v1, v2) => {
			if (v1 === v2) return 0;
			if (!v1) return 1;
			if (!v2) return -1;
			const num1 = /^\d+$/.test(v1.trim());
			const num2 = /^\d+$/.test(v2.trim());
			if (num1 && num2) return parseInt(v1, 10) - parseInt(v2, 10);
			if (num1) return -1;
			if (num2) return 1;
			return v1
				.trim()
				.localeCompare(v2.trim(), undefined, { sensitivity: "base" });
		},
	},
	jobTitle: {
		field: "jobTitle",
		headerName: "Job Title",
		valueGetter: (params) =>
			params.row.jobTitle ? params.row.jobTitle.trim().toLowerCase() : "",
	},
	name: {
		field: "name",
		headerName: "Worker Name",
		valueGetter: (params) => `${params.row.firstName} ${params.row.lastName}`,
		customCell: true,
	},
	workerType: {
		field: "workerType",
		headerName: "Worker Type",
		valueGetter: (params) => params.row.workerType,
	},
	workerSubTypeText: {
		field: "workerSubTypeText",
		headerName: "Worker Sub Type",
		width: 140,
		valueGetter: (params) => params.row.workerSubTypeText,
	},
	skillScore: {
		field: "skillScore",
		headerName: "Total Skill Score",
		width: 160,
		valueFormatter: (params) => formatSkillScore(params.row?.skillScore) ?? "0",
	},
	managerName: {
		field: "managerName",
		headerName: "Supervisor",
		valueGetter: (params) => params.row.managerName?.toLowerCase(),
	},
	workCenter: {
		field: "workCenter",
		headerName: "Work Center",
		valueGetter: (params) => params.row.workCenter,
	},
	shiftPatternName: {
		field: "shiftPatternName",
		headerName: "Shift Pattern",
		valueGetter: (params) => params.row.shiftPatternName,
	},
};

const createColumns = (selectedColumns, searchText) => {
	return selectedColumns
		.filter((column) => column.field in COLUMN_CONFIGS)
		.map((column) => {
			const config = COLUMN_CONFIGS[column.field];
			return {
				...config,
				width: config.width || 200,
				headerAlign: "left",
				renderHeader: () => <ColumnHeader text={config.headerName} />,
				renderCell: (params) =>
					config.customCell ? (
						<ColumnEmployeeCell params={params} searchText={searchText} />
					) : (
						<ColumnCell
							text={
								config.valueFormatter
									? config.valueFormatter(params)
									: params.row[config.field] ?? "-"
							}
							searchText={searchText}
						/>
					),
			};
		});
};

export default function CrossSkillingTable({
	data,
	filters,
	allSkillLevels,
	notAssignedSkillLevel,
	allJobs,
	filterStatus,
	allOrganizations,
	dataProcessed,
	selectedColumns,
	searchText,
	getFilter,
}) {
	const {
		apiRef,
		addSkillInterestStatus,
		rejectSkillInterestStatus,
		isJobFilter,
		isSkillPriorityFilter,
		skillPriorityIds,
		isColumnGroup,
		skillLevelIds,
		jobIdsSkillsArr,
		jobIds,
		filteredWorkersByLevel,
		filteredSkills,
		workers,
		handleAddSkillInterested,
		handleSkillLevels,
		columnGroup,
		updateWorkerSkillLevelStatus,
		height,
		width,
		allSkillsOfAllLocations,
		fetchManageWorkersDataStatus,
		skillMatrixFiltersStatus,
		skillMatrixDefaultFiltersStatus,
		skillsUpdatedListInSession,
		isWorkCenterFilter,
		isJobSkillLevelMapping,
	} = useCrossSkillTableController({
		data,
		filters,
		allSkillLevels,
		allJobs,
		getFilter,
		searchText,
	});

	const isSkillInsightsEnabled = useSelector(
		(state) => state.user.userData.skillInsightsEnabled
	);

	const isSkillPriorityEnabled = useSelector(
		(state) => state.user.userData.skillPriorityEnabled
	);
	const isDrawerOpen = useSelector(
		(state) => state.navigationDrawer.isDrawerOpen
	);

	const [loading, setLoading] = useState(true);

	let rows = [];
	let col = [];

	col = createColumns(selectedColumns, searchText);

	const skillsCol = createSkillColumn(
		jobIdsSkillsArr,
		handleSkillLevels,
		allSkillLevels,
		notAssignedSkillLevel,
		handleAddSkillInterested,
		isJobFilter,
		searchText,
		isColumnGroup,
		false,
		skillsUpdatedListInSession
	);

	col = [...col, ...skillsCol];
	if (!isColumnGroup) {
		// Remove duplicate skills when not with column grouping
		col = col.filter(
			(v, i, a) => a.findIndex((v2) => v2.field === v.field) === i
		);
	}
	rows = skillLevelIds.length ? filteredWorkersByLevel ?? [] : workers ?? [];

	const uniqueSkills = jobIdsSkillsArr.filter(
		(obj, index, self) =>
			index === self.findIndex((o) => o.skillId === obj.skillId)
	);

	//check other skills
	if (
		filteredSkills.length > uniqueSkills.length &&
		isColumnGroup &&
		!isWorkCenterFilter
	) {
		const distinctSkills = findOtherSkills(filteredSkills, jobIdsSkillsArr);
		const isOther = true;

		const skillsCol1 = createSkillColumn(
			distinctSkills,
			handleSkillLevels,
			allSkillLevels,
			notAssignedSkillLevel,
			handleAddSkillInterested,
			isJobFilter,
			searchText,
			isColumnGroup,
			isOther,
			skillsUpdatedListInSession
		);

		let obj = {};
		let temp = [];
		distinctSkills.forEach((sk) => {
			temp.push({ field: sk.skillId + sk.jobColor });
			obj.groupId = "000";
			obj.headerName = "Other Skills";
			obj.children = temp;
			obj.renderHeaderGroup = () => (
				<ColumnGroupJobHeader skill={sk} searchText={searchText} />
			);
		});
		if (Object.keys(obj).length > 0) columnGroup.push(obj);

		col = [...col, ...skillsCol1];
	}

	//create column for skills with no jobs for other skill's column group
	const skillsWithNoJobs = skillsWithoutJobs(allSkillsOfAllLocations, allJobs);
	if (
		skillsWithNoJobs.length > 0 &&
		!isJobFilter &&
		isColumnGroup &&
		!isWorkCenterFilter
	) {
		const columnsForOtherSkills = createSkillColumnWithoutJobs(
			skillsWithNoJobs,
			handleSkillLevels,
			allSkillLevels,
			notAssignedSkillLevel,
			handleAddSkillInterested,
			isJobFilter,
			searchText,
			skillsUpdatedListInSession,
			isSkillPriorityFilter,
			skillPriorityIds
		);
		col = [...col, ...columnsForOtherSkills];
	}

	// column for skills with no jobs
	if (jobIds.length > 0 && columnGroup.length > 0) {
		const jobsWithNoSkills = columnGroup.filter((x) => x.noSkill);
		jobsWithNoSkills.forEach((job) => {
			const noSkill = {
				skillId: -2,
				skillName: "No Skill ",
			};

			const noSkillColumn = [
				{
					field: `${job.groupId}-${noSkill.skillId}`,
					headerName: noSkill.skillName,
					width: 100,
					headerAlign: "center",
					renderHeader: () => <ColumnNoSkillHeader />,
				},
			];
			col = [...col, ...noSkillColumn];
		});
	}

	useEffect(() => {
		setLoading(!dataProcessed);
	}, [dataProcessed]);

	return (
		<Box
			sx={{
				"@media (max-width: 1312px)": {
					width: isDrawerOpen ? "calc(100vw - 288px)" : "calc(100vw - 112px)",
				},
				"@media (min-width: 1312px)": {
					width: filterStatus
						? isDrawerOpen
							? "calc(100vw - 569px)"
							: "calc(100vw - 392px)"
						: isDrawerOpen
						? "calc(100vw - 288px)"
						: "calc(100vw - 112px)",
				},
				height:
					height > 890
						? isSkillInsightsEnabled
							? "calc(100vh - 308px)"
							: "calc(100vh - 250px)"
						: "635px",
			}}
			minWidth={320}
		>
			<SortedDataGridPro6
				apiRef={apiRef}
				defaultSortField='name'
				key={`cross-skilling-datagrid-${isJobFilter}-${rows.length}`}
				pagination
				columnVisibilityModel={{
					skillScore: isSkillPriorityEnabled,
				}}
				slots={{
					loadingOverlay: LinearProgress,
				}}
				loading={
					fetchManageWorkersDataStatus === "pending" ||
					skillMatrixFiltersStatus === "pending" ||
					skillMatrixDefaultFiltersStatus === "pending" ||
					updateWorkerSkillLevelStatus === "pending" ||
					addSkillInterestStatus === "pending" ||
					rejectSkillInterestStatus === "pending" ||
					loading
				}
				pageSizeOptions={[25, 50, 100]}
				experimentalFeatures={{
					columnGrouping: isColumnGroup,
					lazyLoading: true,
				}}
				rows={rows}
				columns={col}
				disableRowSelectionOnClick
				columnGroupingModel={columnGroup}
				getRowHeight={() => 50} // Do not set to "auto" memory leak
				columnHeaderHeight={60}
				pinnedColumns={{
					left: [
						"companyEmployeeId",
						"jobTitle",
						"name",
						"workerType",
						"workerSubTypeText",
						"managerName",
						"workCenter",
						"shiftPatternName",
						"skillScore",
					],
				}}
				sx={{
					[`& .${gridClasses.cell}`]: {
						py: 1,
					},
					"& .MuiDataGrid-columnHeader": {
						padding: 0,
					},
					"& .MuiDataGrid-columnHeader:focus": {
						outline: "none",
					},
					"& .MuiDataGrid-row:hover": {
						backgroundColor: "white",
					},
					"& .MuiDataGrid-cell:hover": {
						backgroundColor: "#F8F8F8",
					},
					"& .MuiDataGrid-cell": {
						padding: 0,
					},
					"& .MuiDataGrid-cell:focus": {
						outline: "none",
					},
					"& .hideRightSeparator > .MuiDataGrid-columnSeparator": {
						display: "none",
					},
					"& .MuiDataGrid-pinnedColumnHeaders": {
						height: "100%",
						display: "flex",
						alignItems: "flex-start",
						justifyContent: "flex-end",
						boxShadow:
							"0px 3px 1px -2px rgba(0,0,0,0.0),0px 2px 2px 0px rgba(0,0,0,0.0),0px 1px 5px 0px rgba(0,0,0,0.12)",
					},
					"& .MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitleContainer":
						{
							display: "block",
						},
					backgroundColor: "#fff",
					border: 0,
					"& .MuiTablePagination-root": {
						marginRight: "60px",
					},
				}}
				columnBuffer={5}
				columnThreshold={5}
				disableColumnMenu
			/>
		</Box>
	);
}
