import { Box } from "@mui/material";
import React from "react";

import { useDayActivityController } from "../controllers/use-day-activity-controller";
import ActivityTable from "./activity-table";
import KpiSummaries from "./ui/kpi-summaries";

const DailyActivityReport = () => {
	const [
		{ currentDate, filteredData, exportData, search, columnSelector, open, allFilters },
		{ setCurrentDate, setSearch, setColumnSelector, setFilterOpen, setAllFilters },
	] = useDayActivityController();
	return (
		<Box sx={{ display: "flex", flexDirection: "column" }}>
			<KpiSummaries />
			<ActivityTable
				date={currentDate}
				setDate={setCurrentDate}
				data={filteredData}
				exportData={exportData}
				search={search}
				setSearch={setSearch}
				columnSelector={columnSelector}
				setColumnSelector={setColumnSelector}
				open={open}
				setFilterOpen={setFilterOpen}
				allFilters={allFilters}
				setAllFilters={setAllFilters}
			/>
		</Box>
	);
};

export default DailyActivityReport;
