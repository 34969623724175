export const SENIORITY = "SENIORITY";
export const FASTEST = "FASTEST";
export const PRIMARYJOB = "PRIMARYJOB";
export const JOB = "JOB ROLES";
export const SKILLS = "SKILLS";
export const WORKCENTER = "WORKCENTER";
export const LOCATION = "LOCATION";
export const FULL_TIME_WORKER = "FullTime";
export const PART_TIME_WORKER = "PartTime";
export const FLEX_WORKER = "FlexWorker";
export const SHIFT_DETAILS = "Shift Details";
export const ASSIGN_SHIFT = "Assign Shift";
export const SET_PREFERRED_WORKERS = "Forced Overtime Preference";
export const ERROR = "Error";
export const WARNING = "Warning";
export const SUCCESS = "Success";

export const DUMMY_PRIORITIES_ARRAY = [
	{
		id: 0,
		code: "A",
		name: "Default",
		description: "Default Priority",
		multiplier: 1,
		hexColor: "",
		isDefault: true,
	},
];

export const DEFAULT_COLORS = {
	A: "F2FCF5",
	B: "FFFADE",
	C: "FFF6F6",
	D: "F3F9FD",
	Z: "F2EFFF",
};

export const ALL_FILTERS = [
	"job",
	"skill",
	"jobSkill",
	"orphanSkill",
	"location",
	"shiftPattern",
	"manager",
	"workerSubType",
	"workertype",
	"attendanceStatus"
];

export const SCHEDULER_FILTERS = [
	"job",
	"skill",
	"jobSkill",
	"location",
	"workCenters",
	"manager",
];

export const WORKER_TABLE_FILTERS = [
	"job",
	"location",
	"workCenters",
	"shiftPattern",
	"manager",
	"workertype",
];

export const ATTENDANCE_REPORT_FILTERS = [
	"location",
	"workCenters",
	"manager",
	"workerType",
	"attendanceStatus",
];

export const WORKER_ACTIVITY_FILTERS = [
	"location",
	"workCenters",
	"manager",
	"workerType",
	"attendanceStatus",
];
